import createDataContext from './createDataContext';

const cartReducer = (state, action) => {
  let { cart } = state;
  switch (action.type) {
    case 'add':
      if (cart.find(item => item.itemName === action.payload.itemName)) {
        cart = cart.map(item =>
          item.itemName === action.payload.itemName
            ? {
              ...item,
              amount:
                parseFloat(item.amount) + parseFloat(action.payload.amount)
            }
            : item
        );
      } else {
        cart = [...cart, action.payload];
      }
      window.localStorage.setItem('azka_cart', JSON.stringify(cart));
      return { ...state, cart, errorMessage: '' };
    case 'remove':
      cart = cart.filter(item => item.itemName !== action.payload);
      window.localStorage.setItem('azka_cart', JSON.stringify(cart));
      return {
        ...state,
        cart,
        errorMessage: ''
      };
    case 'reset':
      window.localStorage.removeItem('azka_cart');
      return { ...state, cart: action.payload, errorMessage: '' };
    default:
      return state;
  }
};

const addToCart = dispatch => async item => {
  dispatch({ type: 'add', payload: item });
};

const removeFromCart = dispatch => async itemName => {
  dispatch({ type: 'remove', payload: itemName });
};

const resetCart = dispatch => async () => {
  dispatch({ type: 'reset', payload: [] });
};

export const { Context, Provider } = createDataContext(
  cartReducer,
  { addToCart, removeFromCart, resetCart },
  {
    cart: window.localStorage.getItem('azka_cart')
      ? JSON.parse(window.localStorage.getItem('azka_cart'))
      : [],
    errorMessage: ''
  }
);
