import React, { useState, useContext, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  Fade,
  MenuItem,
  Select,
  CircularProgress
} from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import CustomModal from '../../components/CustomModal';
import { Context as AuthContext } from '../../context/AuthContext';
import { Context as CategoryContext } from '../../context/CategoryContext';
import { Context as ItemContext } from '../../context/ItemContext';

const DeleteSubCategory = ({ history }) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalText, setModalText] = useState('');
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);
  const {
    fetchCategories,
    fetchSubCategories,
    deleteSubCategory,
    state: { categories, subcategories }
  } = useContext(CategoryContext);

  const {
    fetchItems,
    deleteItem,
    state: { items }
  } = useContext(ItemContext);

  const classes = useStyles();

  useEffect(() => {
    async function fetchCategoriesData() {
      setLoading(true);
      await fetchCategories();
      setLoading(false);
    }

    tryLocalSignin(history, '/delete_subcategory');
    fetchCategoriesData();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    const res = await deleteSubCategory(selectedSubCategoryId);
    items.forEach(async item => {
      const del_item_res = await deleteItem(item._id);
      //   if (del_item_res.error) {
      //     res.error = 'error in deleting item inside subcategory';
      //   }
    });
    if (res.error) {
      setModalText('There is a problem in deleting the subcategory!');
    } else {
      setModalText('Subcategory deleted successfully');
      setSelectedSubCategoryId(null);
      await fetchSubCategories(selectedCategoryId);
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  };

  if (!token) {
    return null;
  }

  return (
    <div>
      <Container style={{ marginTop: '1%' }}>
        {loading && (
          <CircularProgress
            style={{
              display: 'flex',
              position: 'absolute',
              alignSelf: 'center',
              top: '50%',
              left: '50%'
            }}
          />
        )}
        <Fade timeout={1000} in={true}>
          <div>
            <Typography className={classes.title}>
              Delete Subcategory
            </Typography>
            <Divider />
            <Grid
              className={
                isMobileOnly ? classes.mobileContainer : classes.container
              }
              container
            >
              <Grid md={6} xs={12}>
                <Typography className={classes.chooseText}>
                  Choose Category
                </Typography>
                <Select
                  placeholder="Choose category"
                  variant="outlined"
                  className={classes.selectCategory}
                  onChange={async event => {
                    setSelectedSubCategoryId(null);
                    setSelectedCategoryId(event.target.value);
                    setLoading(true);
                    await fetchSubCategories(event.target.value);
                    setLoading(false);
                  }}
                >
                  {categories.map(category => (
                    <MenuItem value={category._id}>
                      {category.englishName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid md={6} xs={12}>
                <Typography className={classes.chooseText}>
                  Choose Subcategory
                </Typography>
                <Select
                  placeholder="Choose category"
                  variant="outlined"
                  className={classes.selectCategory}
                  onChange={async event => {
                    setSelectedSubCategoryId(event.target.value);
                    await fetchItems(event.target.value);
                  }}
                >
                  {subcategories.map(subcategory => (
                    <MenuItem value={subcategory._id}>
                      {subcategory.englishName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (!selectedSubCategoryId) {
                  return;
                }

                setModalText(
                  'Do you want to submit this? All the Items belong the selected subcategory will be deleted'
                );
                setShowConfirmButton(true);
                setModalVisible(true);
              }}
            >
              Submit
            </Button>
            <CustomModal
              title="Warning!"
              content={modalText}
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}
              onConfirm={handleSubmit}
              showConfirmButton={showConfirmButton}
              loading={loading}
            />
          </div>
        </Fade>
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em'
  },
  container: {
    marginTop: '2%'
  },
  mobileContainer: {
    marginTop: '5%'
  },
  chooseText: {
    fontSize: '1.3em',
    marginBottom: '1%'
  },
  selectCategory: {
    width: isMobileOnly ? '85%' : '50%'
    //marginBottom: isMobileOnly ? '5%' : 0
  },
  uploadButton: {
    marginTop: '5%'
  },
  submitButton: {
    float: 'right',
    width: isMobileOnly ? '35%' : '10%',
    marginTop: isMobileOnly ? '5%' : 0,
    marginBottom: '2%'
  }
});

export default DeleteSubCategory;
