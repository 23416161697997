import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Typography,
  Divider,
  Container,
  Grid,
  Card,
  CardActions,
  CardActionArea,
  CardMedia,
  CardContent,
  Button,
  ButtonBase,
  CircularProgress
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Aos from 'aos';
import 'aos/dist/aos.css';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import CustomModal from '../components/CustomModal';
import { useLocation, useHistory } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { Context as ItemContext } from '../context/ItemContext';
import { Context as CartContext } from '../context/CartContext';
import { Context as LanguageContext } from '../context/LanguageContext';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const AWS_URL = 'https://azka-bucket.s3.eu-central-1.amazonaws.com/';

const ItemsScreen = () => {
  const [cartItem, setCartItem] = useState(null);
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const subcategoryId = location.state.subcategoryId;

  const {
    fetchItems,
    resetItemsState,
    state: { items }
  } = useContext(ItemContext);

  const {
    state: { language }
  } = useContext(LanguageContext);

  const props = {
    language
  };

  const { addToCart } = useContext(CartContext);

  useEffect(() => {
    Aos.init({
      duration: 2000
    });

    async function fetchData() {
      setLoading(true);
      await fetchItems(subcategoryId);
      setLoading(false);
    }

    fetchData();

    return function cleanup() {
      resetItemsState();
    };
  }, []);

  const handleAddingToCart = async () => {
    setLoading(true);
    const { englishName, arabicName, price, mainImage, _id } = cartItem;
    await addToCart({
      itemId: _id,
      itemName: englishName,
      arabicName,
      mainImage,
      price,
      amount: 1
    });

    setModalText(
      language === 'en' ? 'Item Added To Cart' : 'تمت الإضافة إلى السلة'
    );
    setShowConfirmButton(false);
    setModalVisible(true);
    setCartItem(null);
    setLoading(false);
  };

  const classes = useStyles(props);
  return (
    <div>
      <NavBar />
      <Container>
        {/* {loading && (
          <CircularProgress
            style={{
              display: 'flex',
              position: 'absolute',
              alignSelf: 'center',
              top: '50%',
              left: '50%',
              color: '#92c83e'
            }}
          />
        )} */}
        <Typography data-aos="fade-down" variant="h2" className={classes.title}>
          {language === 'en' ? 'Our Beautiful Prodcuts' : 'المنتجات'}
        </Typography>
        <Divider />
        <div data-aos="fade-right" className={classes.root}>
          {loading &&
            [1, 2, 3, 4].map(x => (
              <Grid item md={3} xs={6} className={classes.standImageContainer}>
                <ButtonBase
                  focusRipple
                  key={x}
                  className={classes.image}
                  focusVisibleClassName={classes.focusVisible}
                  style={{
                    width: '90%',
                    marginBottom: '5%',
                    marginInline: '2%'
                  }}
                >
                  <Skeleton
                    variant="rect"
                    width={isMobileOnly ? windowWidth / 2 : windowWidth}
                    height={
                      isMobileOnly ? windowHeight / 3 : windowHeight / 2.075
                    }
                  />
                  <img
                    src={require('../images/logo_admin.png').default}
                    alt="logo"
                    style={{
                      objectFit: 'contain',
                      height: windowHeight / 8,
                      cursor: 'pointer',
                      position: 'absolute',
                      top: isMobileOnly ? '20%' : '40%'
                    }}
                  />
                </ButtonBase>
              </Grid>
            ))}
          {items.map(item => (
            <Grid item md={3} xs={6} className={classes.standImageContainer}>
              <Card className={classes.cardContainer}>
                <>
                  <CardActionArea
                    onClick={() =>
                      history.push({
                        pathname: '/detailditem',
                        state: { item }
                      })
                    }
                    className={classes.cardActionArea}
                  >
                    <CardMedia
                      className={
                        isMobileOnly
                          ? classes.mobileCardImage
                          : classes.cardImage
                      }
                      image={AWS_URL + item.mainImage}
                      title="Contemplative Reptile"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        align="center"
                        variant="h5"
                        component="h2"
                      >
                        {item.englishName}
                      </Typography>
                      <Typography className={classes.price} align="center">
                        {language === 'en' ? 'Price:' : 'السعر:'}{' '}
                        <span style={{ color: 'red' }}>
                          {item.price} {language === 'en' ? 'JD' : 'دينار'}
                        </span>
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'column',
                      height: windowHeight / 20
                    }}
                  >
                    <Button
                      size="small"
                      className={classes.addToCart}
                      variant="contained"
                      onClick={() => {
                        setCartItem(item);
                        setModalText(
                          language === 'en'
                            ? `Do you want to add ${item.englishName} to the cart?`
                            : `هل ترغب بإضافة ${item.arabicName} إلى السلة؟`
                        );
                        setShowConfirmButton(true);
                        setModalVisible(true);
                      }}
                    >
                      {language === 'en' ? 'Add to Cart' : 'أضف إلى السلة'}
                    </Button>
                  </CardActions>
                </>
              </Card>
              <CustomModal
                visible={modalVisible}
                title={
                  language === 'en' ? 'Adding to Cart' : 'الإضافة إلى السلة'
                }
                loading={loading}
                content={modalText}
                showConfirmButton={showConfirmButton}
                onCancel={() => setModalVisible(false)}
                onConfirm={handleAddingToCart}
              />
            </Grid>
          ))}
        </div>
      </Container>
      <Footer />
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    marginTop: '7%',
    color: '#92c83e',
    fontSize: isMobileOnly ? '1.8em' : '2.5em'
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: '2%'
  },
  standImageContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  cardContainer: {
    marginInline: '2%',
    marginBottom: '5%'
  },
  cardActionArea: {
    '&:hover': {
      color: 'black'
    }
  },
  cardImage: {
    height: windowHeight / 3,
    width: windowWidth / 6
  },
  mobileCardImage: {
    height: windowHeight / 3,
    width: windowWidth / 2
  },
  price: {
    fontWeight: 'bold'
  },
  addToCart: {
    backgroundColor: '#92c83e',
    color: 'white',
    '&:hover': {
      backgroundColor: '#92c83e',
      color: 'white'
    },
    alignSelf: 'center'
  }
});

export default ItemsScreen;
