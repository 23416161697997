import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  Fade,
  TextField,
  CircularProgress
} from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import { Context as AuthContext } from '../../context/AuthContext';
import { Context as ArticleContext } from '../../context/ArticleContext';
import CustomModal from '../../components/CustomModal';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const AddArticle = ({ history }) => {
  const classes = useStyles();
  const [articleImage, setArticleImage] = useState(null);
  const [arabicArticleTitle, setArabicArticleTitle] = useState('');
  const [englishArticleTitle, setEnglishArticleTitle] = useState('');
  const [arabicArticleText, setArabicArticleText] = useState('');
  const [englishArticleText, setEnglishArticleText] = useState('');
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [resetPhoto, setResetPhoto] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);
  const { uploadArticle } = useContext(ArticleContext);

  useEffect(() => {
    tryLocalSignin(history, '/add_article');
  }, []);

  const handleSubmit = async event => {
    setLoading(true);

    const articleData = {
      arabicTitle: arabicArticleTitle,
      englishTitle: englishArticleTitle,
      arabicBody: arabicArticleText,
      englishBody: englishArticleText
    };
    const res = await uploadArticle(articleData, articleImage);

    if (res.error) {
      setModalText('There is a problem in uploading the Article!');
    } else {
      setModalText('Article uploaded successfully');
      setArabicArticleTitle('');
      setEnglishArticleTitle('');
      setArabicArticleText('');
      setEnglishArticleText('');
      setArticleImage(null);
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  };

  const validateImagesSizes = async event => {
    event.preventDefault();

    Object.values(event.target.files).forEach(imgFile => {
      if (imgFile.size > 1048576) {
        setModalText('There are images that exceed 1MB limit size');
        setModalVisible(true);
        setShowConfirmButton(false);
        setResetPhoto(true);
        return;
      }
    });

    setArticleImage(event.target.files[0]);
  };

  if (!token) {
    return null;
  }

  return (
    <div>
      <Container style={{ marginTop: '1%' }}>
        {loading && (
          <CircularProgress
            style={{
              display: 'flex',
              position: 'absolute',
              alignSelf: 'center',
              top: '50%',
              left: '50%'
            }}
          />
        )}
        <Fade timeout={1000} in={true}>
          <div>
            <Typography className={classes.title}>Add Article</Typography>
            <Divider />
            <Grid className={classes.container} container>
              <Grid direction="row" md={4} xs={12}>
                <Button
                  className={isMobileOnly ? classes.uploadButton : ''}
                  variant="contained"
                  component="label"
                  color="primary"
                >
                  Upload Article Photo
                  <input
                    name="photos"
                    type="file"
                    id="files"
                    onChange={event => validateImagesSizes(event)}
                    hidden
                  />
                </Button>
                <Typography className={classes.uploadText}>
                  {articleImage
                    ? 'Image selected'
                    : 'Please Upload Article Image'}
                </Typography>
              </Grid>
              <Grid direction="row" md={4} xs={12}>
                <TextField
                  value={englishArticleTitle}
                  onChange={e => setEnglishArticleTitle(e.target.value)}
                  placeholder="English Article Title"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid direction="row" md={4} xs={12}>
                <TextField
                  value={arabicArticleTitle}
                  onChange={e => setArabicArticleTitle(e.target.value)}
                  placeholder="Arabic Article Title"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  value={englishArticleText}
                  onChange={e => setEnglishArticleText(e.target.value)}
                  className={classes.descriptionField}
                  placeholder="English Article Text"
                  variant="outlined"
                  multiline
                  inputProps={{
                    style: {
                      height: windowHeight / 4,
                      width: isMobileOnly
                        ? windowWidth / 1.6
                        : windowWidth / 1.5
                    }
                  }}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  value={arabicArticleText}
                  onChange={e => setArabicArticleText(e.target.value)}
                  className={classes.descriptionField}
                  placeholder="نص المقال بالعربية"
                  variant="outlined"
                  multiline
                  inputProps={{
                    style: {
                      height: windowHeight / 4,
                      width: isMobileOnly
                        ? windowWidth / 1.6
                        : windowWidth / 1.5,
                      textAlign: 'right'
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (
                  !articleImage ||
                  !arabicArticleTitle ||
                  !englishArticleTitle ||
                  !arabicArticleText ||
                  !englishArticleText
                ) {
                  return;
                }

                setModalText('Do you want to submit this?');
                setShowConfirmButton(true);
                setModalVisible(true);
                setResetPhoto(false);
              }}
            >
              Submit
            </Button>
            <CustomModal
              title="Warning!"
              content={modalText}
              visible={modalVisible}
              onCancel={() => {
                setModalVisible(false);
                if (resetPhoto) setArticleImage(null);
              }}
              onConfirm={handleSubmit}
              showConfirmButton={showConfirmButton}
              loading={loading}
            />
          </div>
        </Fade>
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em'
  },
  container: {
    marginTop: isMobileOnly ? '5%' : '2%'
  },
  textField: {
    width: isMobileOnly ? '85%' : '70%',
    marginBottom: isMobileOnly ? '5%' : 0,
    marginTop: '5%'
  },
  descriptionField: {
    marginTop: '5%'
  },
  submitButton: {
    float: 'right',
    width: isMobileOnly ? '35%' : '10%',
    marginTop: '5%',
    marginBottom: '2%'
  }
});

export default AddArticle;
