import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  Fade,
  MenuItem,
  Select,
  CircularProgress
} from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import CustomModal from '../../components/CustomModal';
import { Context as AuthContext } from '../../context/AuthContext';
import { Context as CategoryContext } from '../../context/CategoryContext';
import { Context as ItemContext } from '../../context/ItemContext';

const DeleteCategory = ({ history }) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);
  const {
    fetchCategories,
    fetchSubCategories,
    deleteSubCategory,
    deleteCategory,
    state: { categories, subcategories }
  } = useContext(CategoryContext);

  const { fetchItems, deleteItem } = useContext(ItemContext);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await fetchCategories();
      setLoading(false);
    }

    tryLocalSignin(history, '/delete_category');
    fetchData();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    const res = await deleteCategory(selectedCategoryId);
    subcategories.forEach(async subcategory => {
      const del_subcategory_res = await deleteSubCategory(subcategory._id);
      //   if (del_subcategory_res.error) {
      //     res.error = 'error in deleting subcategory inside category';
      //   }
      const fetch_items_res = await fetchItems(subcategory._id);
      fetch_items_res.items.forEach(async item => {
        const del_item_res = await deleteItem(item._id);
        //   if (del_item_res.error) {
        //     res.error = 'error in deleting item inside subcategory';
        //   }
      });
    });

    if (res.error) {
      setModalText('There is a problem in deleting the Category!');
    } else {
      setModalText('Category uploaded successfully');
      setSelectedCategoryId(null);
      fetchCategories();
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  };

  const classes = useStyles();

  if (!token) {
    return null;
  }

  return (
    <div>
      <Container style={{ marginTop: '1%' }}>
        {loading && (
          <CircularProgress
            style={{
              display: 'flex',
              position: 'absolute',
              alignSelf: 'center',
              top: '50%',
              left: '50%'
            }}
          />
        )}
        <Fade timeout={1000} in={true}>
          <div>
            <Typography className={classes.title}>Delete Category</Typography>
            <Divider />
            <Grid
              className={
                isMobileOnly ? classes.mobileContainer : classes.container
              }
              container
            >
              <Grid xs={12}>
                <Typography className={classes.chooseText}>
                  Choose Category
                </Typography>
                <Select
                  placeholder="Choose category"
                  variant="outlined"
                  className={classes.selectCategory}
                  onChange={async event => {
                    setSelectedCategoryId(event.target.value);
                    await fetchSubCategories(event.target.value);
                  }}
                >
                  {categories.map(category => {
                    return (
                      <MenuItem value={category._id}>
                        {category.englishName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (!selectedCategoryId) {
                  return;
                }

                setModalText(
                  'Do you want to submit this? All Subcategories and Items belonging to the selected category will be deleted'
                );
                setShowConfirmButton(true);
                setModalVisible(true);
              }}
            >
              Submit
            </Button>
            <CustomModal
              title="Warning!"
              content={modalText}
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}
              onConfirm={handleSubmit}
              showConfirmButton={showConfirmButton}
              loading={loading}
            />
          </div>
        </Fade>
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em'
  },
  container: {
    marginTop: '2%'
  },
  mobileContainer: {
    marginTop: '5%'
  },
  chooseText: {
    fontSize: '1.3em',
    marginBottom: '1%'
  },
  selectCategory: {
    width: isMobileOnly ? '85%' : '50%'
    //marginBottom: isMobileOnly ? '5%' : 0
  },
  uploadButton: {
    marginTop: '5%'
  },
  submitButton: {
    float: 'right',
    width: isMobileOnly ? '35%' : '10%',
    marginTop: isMobileOnly ? '5%' : 0,
    marginBottom: '2%'
  }
});

export default DeleteCategory;
