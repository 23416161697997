import React, { useState, useContext, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Container,
  Fade,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress
} from '@material-ui/core';
import { io } from 'socket.io-client';
import { ExpandMore } from '@material-ui/icons';
import { isMobileOnly } from 'react-device-detect';
import { Context as OrderContext } from '../context/OrderContext';
import { Context as AuthContext } from '../context/AuthContext';

const Orders = ({ history }) => {
  let socket;
  const [loading, setLoading] = useState(false);

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);
  const {
    fetchOrders,
    state: { orders }
  } = useContext(OrderContext);

  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await fetchOrders();
      setLoading(false);
    }

    async function setupForNotifications() {
      const response = await Notification.requestPermission();
      if (response === 'granted') {
        socket = io('https://azka-app.herokuapp.com', {
          // 'http://localhost:5000'
          transports: ['websocket']
        });

        socket.on('order uploaded', fullName => {
          new Notification('New Order Recieved!', {
            body: `from ${fullName}`
          });

          fetchData();
        });
      }
    }

    tryLocalSignin(history, '/orders');

    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
    } else {
      setupForNotifications();
    }

    fetchData();

    return function cleanup() {
      try {
        socket.disconnect();
      } catch {
        console.log('permission not granted!');
      }
    };
  }, []);

  if (!token) {
    return null;
  }

  return (
    <Container style={{ marginTop: '1%' }}>
      <Fade timeout={1000} in={true}>
        <div>
          <Typography className={classes.title}>Orders</Typography>
          <Divider />
          {loading && (
            <CircularProgress
              style={{
                display: 'flex',
                position: 'absolute',
                alignSelf: 'center',
                top: '50%',
                left: '50%'
              }}
            />
          )}
          {orders.map(order => {
            const dateAndTime = new Date(order.createdAt);
            const date =
              dateAndTime.getUTCDate() +
              '/' +
              (dateAndTime.getMonth() + 1) +
              '/' +
              dateAndTime.getUTCFullYear();
            return (
              <Accordion className={classes.accordionContainer}>
                <AccordionSummary
                  expandIcon={<ExpandMore className={classes.headerText} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ backgroundColor: 'green' }}
                >
                  <Grid md={12}>
                    <Typography className={classes.headerText}>
                      Client Name: {order.fullName}
                    </Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid md={4} xs={12}>
                      <Typography className={classes.details}>
                        <span style={{ fontWeight: 'bold' }}>Date:</span> {date}
                      </Typography>
                    </Grid>
                    <Grid md={4} xs={12}>
                      <Typography className={classes.details}>
                        <span style={{ fontWeight: 'bold' }}>
                          Phone Number:
                        </span>{' '}
                        {order.phoneNumber}
                      </Typography>
                    </Grid>
                    <Grid md={4} xs={12}>
                      <Typography className={classes.details}>
                        <span style={{ fontWeight: 'bold' }}>Address:</span>{' '}
                        {order.address}
                      </Typography>
                    </Grid>
                    <Grid md={4} xs={12}>
                      <Typography className={classes.details}>
                        <span style={{ fontWeight: 'bold' }}>Governorate:</span>{' '}
                        {order.governorate}
                      </Typography>
                    </Grid>
                    <Grid md={4} xs={12}>
                      <Typography className={classes.details}>
                        <span style={{ fontWeight: 'bold' }}>
                          Payment method:
                        </span>{' '}
                        {order.paymentMethod}
                      </Typography>
                    </Grid>
                    <Grid md={4} xs={12}>
                      <Typography className={classes.details}>
                        <span style={{ fontWeight: 'bold' }}>
                          Delivery Price:
                        </span>{' '}
                        {order.deliveryPrice}JD
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <Typography style={{ marginBottom: '5%' }}>
                        <span style={{ fontWeight: 'bold' }}>Totla Price:</span>{' '}
                        {order.totalPrice}JD
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <Typography className={classes.orderDetailsText}>
                        Order Details:
                      </Typography>
                    </Grid>
                    {order.items.map(({ englishName, price, amount }) => (
                      <>
                        <Grid md={4} xs={12}>
                          <Typography className={classes.details}>
                            <span style={{ fontWeight: 'bold' }}>
                              Item Name:
                            </span>{' '}
                            {englishName}
                          </Typography>
                        </Grid>
                        <Grid md={4} xs={12}>
                          <Typography className={classes.details}>
                            <span style={{ fontWeight: 'bold' }}>Amount:</span>{' '}
                            {amount} Piece
                          </Typography>
                        </Grid>
                        <Grid md={4} xs={12}>
                          <Typography className={classes.details}>
                            <span style={{ fontWeight: 'bold' }}>Price:</span>{' '}
                            {price}JD
                          </Typography>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
          {/* ))
          )} */}
        </div>
      </Fade>
    </Container>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em'
  },
  accordionContainer: {
    marginTop: '2%',
    marginBottom: '2%',
    borderRadius: 15
  },
  headerText: {
    color: 'white'
  },
  details: {
    marginBottom: isMobileOnly ? '8%' : '5%'
  },
  orderDetailsText: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    marginBottom: '2%'
  }
});

export default Orders;
