import React, { useState, useContext, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container
} from '@material-ui/core';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useLocation } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Slider from '../components/Carousel';
import '../css/Slider.css';
import CustomModal from '../components/CustomModal';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import AccordionExpand from '../components/Accordion';
import { Context as CartContext } from '../context/CartContext';
import { Context as LanguageContext } from '../context/LanguageContext';
import { isMobileOnly } from 'react-device-detect';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const DetaildItemScreen = () => {
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(1);

  const classes = useStyles();

  const { addToCart } = useContext(CartContext);

  const {
    state: { language }
  } = useContext(LanguageContext);

  const location = useLocation();
  const {
    _id,
    englishName,
    arabicName,
    mainImage,
    images,
    price,
    props,
    englishDescription,
    arabicDescription,
    englishIngredients,
    arabicIngredients,
    englishStorage,
    arabicStorage,
    englishNutritionFacts,
    arabicNutritionFacts,
    nutritionFactsImages
  } = location.state.item;

  const handleAddingToCart = async () => {
    setLoading(true);
    await addToCart({
      itemId: _id,
      itemName: englishName,
      arabicName,
      mainImage,
      price,
      amount
    });
    setModalText(language === 'en' ? 'Item Added To Cart' : 'تمت الإضافة إلى السلة');
    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  };

  return (
    <div>
      <NavBar />
      <Container>
        {/* <div className={classes.toolbar}></div> */}
        <Grid container className={classes.carouselContainer}>
          <Grid direction="column" md={6} xs={12}>
            <Slider
              images={images}
              carouselimg={classes.carouselImage}
              showShopNowButton={false}
            />
            <Grid
              direction="row"
              className={classes.categoryUnderSliderContainer}
            >
              {props.map(prop => (
                <img
                  src={require(`../images/${prop}.jpeg`).default}
                  alt={prop}
                  className={classes.categoryUnderSlider}
                />
              ))}
            </Grid>
          </Grid>
          <Grid md={6} xs={12} style={{ textAlign: 'center' }}>
            <Typography className={classes.productName}>
              {language === 'en' ? englishName : arabicName}
            </Typography>
            <Divider />
            <Typography className={classes.price}>
              {language === 'en' ? 'Price:' : 'السعر:'} {price}{' '}
              {language === 'en' ? 'JD' : 'دينار'}
            </Typography>
            <Grid direction="row" className={classes.amountContainer}>
              <AddCircle
                className={classes.addCircle}
                fontSize="large"
                onClick={() => setAmount(amount + 1)}
              />
              <input
                className="border rounded-pill text-center"
                style={{
                  width: 150,
                  height: 40,
                  outline: 'none'
                }}
                name="amount"
                placeholder="Quantity"
                type="number"
                disabled
                value={amount}
              />
              <RemoveCircle
                className={classes.minusCircle}
                fontSize="large"
                color="secondary"
                onClick={() => setAmount(Math.max(1, amount - 1))}
              />
            </Grid>
            <Button
              variant="contained"
              color="primary"
              className={classes.addToCartButton}
              onClick={() => {
                setModalText(
                  language === 'en'
                    ? `Do you want to add ${amount} ${englishName} to the cart?`
                    : `هل ترغب بإضافة ${amount} ${arabicName} إلى السلة؟`
                );
                setShowConfirmButton(true);
                setModalVisible(true);
              }}
            >
              {language === 'en' ? 'Add to Cart' : 'أضف إلى السلة'}
            </Button>
            <AccordionExpand
              title={language === 'en' ? 'Description' : 'الوصف'}
              description={
                language === 'en' ? englishDescription : arabicDescription
              }
            />
            <AccordionExpand
              title={language === 'en' ? 'Ingredients' : 'المكونات'}
              description={
                language === 'en' ? englishIngredients : arabicIngredients
              }
            />
            <AccordionExpand
              title={language === 'en' ? 'Nutrition facts' : 'حقائق غذائية'}
              description={
                language === 'en' ? englishNutritionFacts : arabicNutritionFacts
              }
              images={nutritionFactsImages}
            />
            <AccordionExpand
              title={language === 'en' ? 'Storage' : 'التخزين'}
              description={language === 'en' ? englishStorage : arabicStorage}
            />
          </Grid>
        </Grid>
      </Container>
      <CustomModal
        visible={modalVisible}
        title={language === 'en' ? 'Adding to Cart' : 'الإضافة إلى السلة'}
        loading={loading}
        content={modalText}
        showConfirmButton={showConfirmButton}
        onCancel={() => setModalVisible(false)}
        onConfirm={handleAddingToCart}
      />
      <Footer />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  // root: {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  //   width: '100%'
  // },
  carouselContainer: {
    marginTop: '5%'
  },
  carouselImage: {
    height: windowHeight / 2,
    objectFit: 'contain'
  },
  productName: {
    textAlign: 'center',
    color: '#92c83e',
    fontSize: '2.5rem'
  },
  price: {
    textAlign: 'center',
    //color: '#92c83e',
    fontSize: isMobileOnly ? '1.6em' : '2rem',
    marginTop: '3%'
  },
  addCircle: {
    color: '#92c83e',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  minusCircle: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  amountContainer: {
    marginTop: '3%'
  },
  addToCartButton: {
    width: isMobileOnly ? windowWidth / 2 : windowWidth / 5,
    marginTop: '5%',
    fontSize: '0.8em',
    backgroundColor: '#92c83e',
    color: 'white',
    '&:hover': {
      backgroundColor: '#92c83e'
    }
  },
  categoryUnderSliderContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2%'
  },
  categoryUnderSlider: {
    marginLeft: '2%',
    objectFit: 'contain',
    width: isMobileOnly ? '30%' : windowWidth / 14
  }
}));

export default DetaildItemScreen;
