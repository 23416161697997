import axios from 'axios';
import createDataContext from './createDataContext';

const bannerReducer = (state, action) => {
  switch (action.type) {
    case 'error':
      return { ...state, errorMessage: action.payload };
    case 'upload_images':
      return { ...state, errorMessage: '' };
    case 'fetch_images':
        return { ...state, errorMessage: '', images: action.payload }
    default:
      return state;
  }
};

const uploadBannerImages = dispatch => async bannerImages => {
  /*Banner Images Config*/
  let bannerImgsConfigs = [];
  try {
    for (let imgIndx = 0; imgIndx < bannerImages.length; imgIndx++) {
      bannerImgsConfigs.push(
        await axios.get('/api/upload/img', {
          params: { path: 'Banner' }
        })
      );
    }
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while fetching banner images config'
    });
    return { error: 'Error in fetching banner images config' };
  }

  /*Upload Banner Images to AWS*/
  try {
    for (let Index = 0; Index < bannerImgsConfigs.length; Index++) {
      await axios.put(bannerImgsConfigs[Index].data.url, bannerImages[Index], {
        headers: {
          'Content-Type': bannerImages[Index].type
        }
      });
    }
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while uploading banner images to AWS'
    });
    return { error: 'Error in Uploading Banner images to AWS' };
  }

  /*Upload Banner Images to Database*/
  try {
    await axios.post('/api/upload/banner', {
      bannerImagesUrls: bannerImgsConfigs.map(config => config.data.key)
    });
    dispatch({ type: 'upload_images' });
    return { error: '' };
  } catch {
    dispatch({
      type: 'error',
      payload:
        'Something went wrong while uploading banner images to the database'
    });
    return { error: 'Error in Uploading Banner images to the database' };
  }
};

const fetchBannerImages = dispatch => async () => {
    try {
        const res = await axios.get('/api/data/webbanner');
        dispatch({ type: 'fetch_images', payload: res.data });
        return { error: '' };
    } catch(err) {
        dispatch({
            type: 'error',
            payload:
              'Something went wrong while fetching banner images'
        });
        return { error: 'Error in Fetching Banner images' };
    }
};

export const { Context, Provider } = createDataContext(
  bannerReducer,
  { fetchBannerImages, uploadBannerImages },
  { images: [], errorMessage: '' }
);
