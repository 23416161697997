import React, { useContext } from 'react';
import { Carousel } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { makeStyles, Button } from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import { Context as LanguageContext } from '../context/LanguageContext';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const AWS_URL = 'https://azka-bucket.s3.eu-central-1.amazonaws.com/';

const Slider = ({
  images,
  carouselStyle,
  showShopNowButton,
  carouselMobile,
  carouselImage,
  carouselimg,
  carouselImageMobile
}) => {
  const {
    state: { language }
  } = useContext(LanguageContext);

  const props = {
    language
  };

  const classes = useStyles(props);
  const history = useHistory();

  return (
    <Carousel
      style={carouselStyle}
      className={isMobileOnly ? carouselMobile : carouselStyle}
      fade
    >
      {images.map(image => (
        <Carousel.Item
          className={isMobileOnly ? carouselImageMobile : carouselImage}
        >
          <img
            className={`d-block w-100 ${carouselimg}`}
            src={AWS_URL + image}
            alt="First slide"
          />
          {showShopNowButton && (
            <Carousel.Caption>
              <Button
                variant="contained"
                color="primary"
                className={
                  isMobileOnly ? classes.shopButtonMobile : classes.shopButton
                }
                onClick={() => history.push('/shop')}
              >
                {language === 'en' ? 'Shop now' : 'تسوق الآن'}
              </Button>
            </Carousel.Caption>
          )}
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

const useStyles = makeStyles({
  shopButton: {
    width: windowWidth / 4,
    fontSize: ({ language }) => (language === 'en' ? '1.2em' : '1.2em'),
    fontFamily: 'kufam',
    backgroundColor: '#92c83e',
    color: 'white',
    '&:hover': {
      backgroundColor: '#92c83e',
      color: 'white'
    }
  },
  shopButtonMobile: language => ({
    width: windowWidth / 2,
    fontSize: '0.8em',
    backgroundColor: '#92c83e',
    color: 'white',
    '&:hover': {
      backgroundColor: '#92c83e'
    }
  })
});

export default Slider;
