import axios from 'axios';
import createDataContext from './createDataContext';
import { uploadNewImages, delete_images } from './commons';

const categoryReducer = (state, action) => {
  switch (action.type) {
    case 'upload_category':
      return { ...state, errorMessage: '' };
    case 'upload_subcategory':
      return { ...state, errorMessage: '' };
    case 'fetch_categories':
      return { ...state, errorMessage: '', categories: action.payload };
    case 'fetch_subcategories':
      return { ...state, errorMessage: '', subcategories: action.payload };
    case 'edit_category':
      return {
        ...state,
        errorMessage: '',
        categories: state.categories.map(category => {
          if (category._id === action.payload._id) {
            return { ...category, ...action.payload };
          } else {
            return category;
          }
        })
      };
    case 'edit_subcategory':
      return {
        ...state,
        errorMessage: '',
        subcategories: state.subcategories.map(subcategory => {
          if (subcategory._id === action.payload._id) {
            return { ...subcategory, ...action.payload };
          } else {
            return subcategory;
          }
        })
      };
    case '':
      return { ...state, errorMessage: '' };
    case 'delete_subcategory':
      return { ...state, errorMessage: '' };
    case 'delete_category':
      return { ...state, errorMessage: '' };
    case 'reset_subcategories':
      return { ...state, errorMessage: '', subcategories: [] };
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const uploadCategory =
  dispatch => async (arabicName, englishName, imageFile) => {
    /*Category Image Config*/
    let categoryImgConfig;
    try {
      categoryImgConfig = await axios.get('/api/upload/img', {
        params: { path: `Category/${englishName.replace(/\s/g, '')}` }
      });
    } catch (err) {
      dispatch({
        type: 'error',
        payload: 'Something went wrong while fetching category image config'
      });
      return { error: 'Error in fetching category image config' };
    }

    /*Upload Category Image to AWS*/
    try {
      await axios.put(categoryImgConfig.data.url, imageFile, {
        headers: {
          'Content-Type': imageFile.type
        }
      });
    } catch (err) {
      dispatch({
        type: 'error',
        payload: 'Something went wrong while uploading category image to AWS'
      });
      return { error: 'Error in Uploading Category image to AWS' };
    }

    /*Upload Category to Database*/
    try {
      await axios.post('/api/upload/category', {
        arabicName,
        englishName,
        categoryImageUrl: categoryImgConfig.data.key
      });
      dispatch({ type: 'upload_category' });
      return { error: '' };
    } catch {
      // TODO: delete recently Added Image
      dispatch({
        type: 'error',
        payload: 'Something went wrong while uploading category to the database'
      });
      return { error: 'Error in Uploading Category to the database' };
    }
  };

const uploadSubCategory =
  dispatch => async (categoryId, arabicName, englishName, imageFile) => {
    let subcategoryImgConfig;
    /*Category Image Config*/
    try {
      subcategoryImgConfig = await axios.get('/api/upload/img', {
        params: { path: `Subcategory/${englishName.replace(/\s/g, '')}` }
      });
    } catch (err) {
      dispatch({
        type: 'error',
        payload: 'Something went wrong while fetching subcategory image config'
      });
      return { error: 'Error in fetching subcategory image config' };
    }

    /*Upload Subcategory Image to AWS*/
    try {
      await axios.put(subcategoryImgConfig.data.url, imageFile, {
        headers: {
          'Content-Type': imageFile.type
        }
      });
    } catch (err) {
      dispatch({
        type: 'error',
        payload: 'Something went wrong while uploading subcategory image to AWS'
      });
      return { error: 'Error in Uploading Subcategory image to AWS' };
    }

    /*Upload Subcategory to Database*/
    try {
      await axios.post('/api/upload/subcategory', {
        categoryId,
        arabicName,
        englishName,
        subcategoryImageUrl: subcategoryImgConfig.data.key
      });
      dispatch({ type: 'upload_subcategory' });
      return { error: '' };
    } catch {
      // TODO: delete recently Added Image
      dispatch({
        type: 'error',
        payload:
          'Something went wrong while uploading subcategory to the database'
      });
      return { error: 'Error in Uploading Subcategory to the database' };
    }
  };

const fetchCategories = dispatch => async () => {
  try {
    const res = await axios.get('/api/data/categories');
    dispatch({ type: 'fetch_categories', payload: res.data });
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while fetching categories'
    });
    return { error: 'Error in Fetching categories' };
  }
};

const fetchSubCategories = dispatch => async categoryId => {
  try {
    const res = await axios.get('/api/data/subcategories', {
      params: { categoryId }
    });
    dispatch({ type: 'fetch_subcategories', payload: res.data });
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while fetching subcategories'
    });
    return { error: 'Error in Fetching subcategories' };
  }
};

const editCategory =
  dispatch =>
  async (
    selectedCategoryId,
    newArabicName,
    newEnglishName,
    newCategoryImage
  ) => {
    try {
      await axios.post('/api/edit/category', {
        categoryId: selectedCategoryId,
        editedFields: {
          arabicName: newArabicName,
          englishName: newEnglishName
        }
      });
      dispatch({
        type: 'edit_category',
        payload: {
          _id: selectedCategoryId,
          arabicName: newArabicName,
          englishName: newEnglishName
        }
      });
    } catch (err) {
      dispatch({
        type: 'error',
        payload: 'Something went wrong while editing the category'
      });
      return { error: 'Error in Editing the category' };
    }

    if (newCategoryImage) {
      const upload_image_res = await uploadNewImages(
        [newCategoryImage],
        `Category/${newEnglishName.replace(/\s/g, '')}`
      );
      if (upload_image_res.error) {
        dispatch({
          type: 'error',
          payload: upload_image_res.error
        });
        return { error: upload_image_res.error };
      }

      try {
        const edit_image_res = await axios.post('/api/edit/category', {
          categoryId: selectedCategoryId,
          editedFields: {
            image: upload_image_res.imgsConfigs.map(
              config => config.data.key
            )[0]
          }
        });
        const delete_res = await delete_images([
          edit_image_res.data.category.image
        ]);
        if (delete_res.error) {
          dispatch({
            type: 'error',
            payload: delete_res.error
          });
          return { error: delete_res.error };
        }
      } catch (err) {
        dispatch({
          type: 'error',
          payload: 'Something went wrong while editing the category'
        });
        return { error: 'Error in Editing the category' };
      }
    }

    return { error: '' };
  };

const editSubCategory =
  dispatch =>
  async (
    selectedSubCategoryId,
    newArabicName,
    newEnglishName,
    newSubCategoryImage
  ) => {
    try {
      await axios.post('/api/edit/subcategory', {
        subcategoryId: selectedSubCategoryId,
        editedFields: {
          arabicName: newArabicName,
          englishName: newEnglishName
        }
      });
      dispatch({
        type: 'edit_subcategory',
        payload: {
          _id: selectedSubCategoryId,
          arabicName: newArabicName,
          englishName: newEnglishName
        }
      });
    } catch (err) {
      dispatch({
        type: 'error',
        payload: 'Something went wrong while editing the subcategory'
      });
      return { error: 'Error in Editing the subcategory' };
    }

    if (newSubCategoryImage) {
      const upload_image_res = await uploadNewImages(
        [newSubCategoryImage],
        `Subcategory/${newEnglishName.replace(/\s/g, '')}`
      );
      if (upload_image_res.error) {
        dispatch({
          type: 'error',
          payload: upload_image_res.error
        });
        return { error: upload_image_res.error };
      }

      try {
        const edit_image_res = await axios.post('/api/edit/subcategory', {
          subcategoryId: selectedSubCategoryId,
          editedFields: {
            image: upload_image_res.imgsConfigs.map(
              config => config.data.key
            )[0]
          }
        });
        const delete_res = await delete_images([
          edit_image_res.data.subcategory.image
        ]);
        if (delete_res.error) {
          dispatch({
            type: 'error',
            payload: delete_res.error
          });
          return { error: delete_res.error };
        }
      } catch (err) {
        dispatch({
          type: 'error',
          payload: 'Something went wrong while editing the subcategory'
        });
        return { error: 'Error in Editing the subcategory' };
      }
    }

    return { error: '' };
  };

const deleteSubCategory = dispatch => async subcategoryId => {
  try {
    const res = await axios.post('/api/delete/subcategory', {
      subcategoryId
    });

    delete_images([res.data.subcategory.image]);

    dispatch({ type: 'delete_subcategory' });
    return { error: '' };
  } catch (err) {
    console.log(err);
    dispatch({
      type: 'error',
      payload:
        'Something went wrong while deleting the subcategory from the database'
    });
    return { error: 'Error in deleting the subcategory from the database' };
  }
};

const deleteCategory = dispatch => async categoryId => {
  try {
    const res = await axios.post('/api/delete/category', {
      categoryId
    });

    delete_images([res.data.category.image]);

    dispatch({ type: 'delete_category', payload: res.data });
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while deleting the category'
    });
    return { error: 'Error in deleting the category' };
  }
};

const resetSubcategoriesState = dispatch => () => {
  dispatch({ type: 'reset_subcategories' });
};

export const { Context, Provider } = createDataContext(
  categoryReducer,
  {
    fetchCategories,
    fetchSubCategories,
    uploadCategory,
    uploadSubCategory,
    editCategory,
    editSubCategory,
    deleteCategory,
    deleteSubCategory,
    resetSubcategoriesState
  },
  { categories: [], subcategories: [], errorMessage: '' }
);
