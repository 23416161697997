import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  TextField,
  Fade
} from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import CustomModal from '../../components/CustomModal';
import { Context as CategoryContext } from '../../context/CategoryContext';
import { Context as AuthContext } from '../../context/AuthContext';

const AddCategory = ({ history }) => {
  const [englishName, setEnglishName] = useState('');
  const [arabicName, setArabicName] = useState('');
  const [categoryImage, setCategoryImage] = useState(null);

  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [resetPhoto, setResetPhoto] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);
  const { uploadCategory } = useContext(CategoryContext);

  useEffect(() => {
    tryLocalSignin(history, '/add_category');
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    const res = await uploadCategory(arabicName, englishName, categoryImage);

    if (res.error) {
      setModalText('There is a problem in uploading the category!');
    } else {
      setModalText('Category uploaded successfully');
      setCategoryImage(null);
      setArabicName('');
      setEnglishName('');
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  };

  const validateImagesSizes = async event => {
    event.preventDefault();

    Object.values(event.target.files).forEach(imgFile => {
      if (imgFile.size > 1048576) {
        setModalText('There are images that exceed 1MB limit size');
        setModalVisible(true);
        setShowConfirmButton(false);
        setResetPhoto(true);
        return;
      }
    });

    setCategoryImage(event.target.files[0]);
  };

  if (!token) {
    return null;
  }

  return (
    <div>
      <Container style={{ marginTop: '1%' }}>
        <Fade timeout={1000} in={true}>
          <div>
            <Typography className={classes.title}>Add Category</Typography>
            <Divider />
            <Grid
              className={
                isMobileOnly ? classes.mobileContainer : classes.container
              }
              container
            >
              <Grid md={4} xs={12}>
                <TextField
                  value={englishName}
                  onChange={e => setEnglishName(e.target.value)}
                  placeholder="English Category Name"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid md={4} xs={12}>
                <TextField
                  value={arabicName}
                  onChange={e => setArabicName(e.target.value)}
                  placeholder="Arabic Category Name"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid direction="row" md={4} xs={12}>
                <Button
                  className={isMobileOnly ? classes.uploadButton : ''}
                  variant="contained"
                  component="label"
                  color="primary"
                >
                  Upload Photo
                  <input
                    name="photos"
                    type="file"
                    id="files"
                    onChange={event => validateImagesSizes(event)}
                    hidden
                  />
                </Button>
                <Typography variant="h6">
                  {categoryImage ? 'Image Selected' : 'Please Select Photo'}
                </Typography>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (!categoryImage || !arabicName || !englishName) {
                  return;
                }

                setModalText('Do you want to submit this?');
                setShowConfirmButton(true);
                setModalVisible(true);
                setResetPhoto(false);
              }}
            >
              Submit
            </Button>
            <CustomModal
              title="Warning!"
              content={modalText}
              visible={modalVisible}
              onCancel={() => {
                setModalVisible(false);
                if (resetPhoto) setCategoryImage(null);
              }}
              onConfirm={handleSubmit}
              showConfirmButton={showConfirmButton}
              loading={loading}
            />
          </div>
        </Fade>
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em'
  },
  container: {
    marginTop: '2%'
  },
  mobileContainer: {
    marginTop: '5%'
  },
  textField: {
    marginBottom: '5%'
  },
  uploadButton: {
    marginTop: '5%'
  },
  submitButton: {
    float: 'right',
    width: isMobileOnly ? '35%' : '10%',
    marginTop: isMobileOnly ? '5%' : 0,
    marginBottom: '2%'
  }
});

export default AddCategory;
