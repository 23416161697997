import React, { useContext } from 'react';
import { makeStyles, Typography, Container } from '@material-ui/core';
import NavBar from '../components/NavBar';
import { Context as LanguageContext } from '../context/LanguageContext';
import { isMobileOnly } from 'react-device-detect';
import Footer from '../components/Footer';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const ContactScreen = () => {
  const {
    state: { language }
  } = useContext(LanguageContext);

  const props = {
    language
  };

  const classes = useStyles(props);
  return (
    <div>
      <NavBar />
      <div className={classes.toolbar}></div>
      <Container>
        <Typography className={classes.title}>
          {language === 'en' ? 'Contact Us' : 'إتصل بنا'}
        </Typography>
        <hr />
        <Typography className={classes.helpText}>
          {language === 'en' ? "We're Here to Help" : 'نحن هنا لخدمتك'}
        </Typography>
        <Typography className={classes.content}>
          {language === 'en'
            ? 'Email us at: sales@azkahd.com'
            : 'sales@azkahd.com : تواصل معنا عبر البريد الإلكتروني'}
        </Typography>
        <Typography className={classes.content}>
          {language === 'en'
            ? 'Call us at: +962 7988601716'
            : '+إتصل بنا على الرقم : 798601716 962'}
        </Typography>
        <Typography className={classes.content}>
          {language === 'en' ? 'Working hours:' : ': أوقات العمل'}
        </Typography>
        <Typography className={classes.content}>
          {language === 'en'
            ? 'Saturday - Thursday : 10 AM - 9:30 PM'
            : 'السبت - الخميس : 10 صباحاً - 9:30 مساءً'}
        </Typography>
        <Typography className={classes.content}>
          {language === 'en'
            ? 'Friday : 4 PM - 9:30 PM'
            : 'الجمعة : 9:30 صباحاً - 4 مساءً'}
        </Typography>
        <Typography className={classes.content}>
          {language === 'en'
            ? 'For Wholesale Business Account Support Contact:'
            : ': للتواصل مع حساب الأعمال بالجملة'}
        </Typography>
        <Typography className={classes.content}>
          supplychain@azkahd.com
        </Typography>
        <Typography className={classes.content}>
          {language === 'en' ? 'AZKA Healthy Desserts' : 'حلويات أزكى الصحية'}
        </Typography>
        <Typography className={classes.content}>
          <a
            target="_blank"
            href="https://maps.app.goo.gl/SyFX8c83xZGJZ2ZXA"
            rel="noopener noreferrer"
            className={classes.address}
          >
            {language === 'en'
              ? 'Amman, St.Shuhadaa Al-Haram Al-Ibrahimi'
              : 'عمّان ، شارع شهداء الحرم الإبراهيمي'}
          </a>
        </Typography>
        <Typography className={classes.content}>
          {language === 'en' ? 'Building No.33' : 'بناية رقم 33'}
        </Typography>
      </Container>
      <Footer />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  title: {
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right'),
    fontSize: isMobileOnly ? '1.6em' : '2em',
    color: '#92c83e'
  },
  helpText: {
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right'),
    fontSize: isMobileOnly ? '1.3em' : '1.8em'
  },
  content: {
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right'),
    fontSize: isMobileOnly ? '1em' : '1.5em',
    marginTop: isMobileOnly ? '5%' : '2%'
  },
  address: {
    color: '#92c83e'
  }
}));

export default ContactScreen;
