import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Typography,
  Divider,
  Button,
  Container,
  Fade
} from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import { DataGrid } from '@material-ui/data-grid';
import CustomModal from '../components/CustomModal';
import { Context as AuthContext } from '../context/AuthContext';
import { Context as ItemContext } from '../context/ItemContext';

const columns = [
  { field: 'id', headerName: 'Item Number', width: 250 },
  {
    field: 'arabicName',
    headerName: 'Arabic Name',
    width: 250,
    editable: false
  },
  {
    field: 'englishName',
    headerName: 'English Name',
    width: 250,
    editable: false
  }
];

const MainItems = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);

  const {
    fetchItems,
    uploadMainItems,
    fetchMainItems,
    state: { items: fetchedItems }
  } = useContext(ItemContext);

  useEffect(() => {
    async function fetchItemsData() {
      setLoading(true);
      const { items } = await fetchItems();
      setRows(
        items.map(({ arabicName, englishName }, index) => ({
          id: index + 1,
          arabicName,
          englishName
        }))
      );

      const { mainItems } = await fetchMainItems();

      const arabicNames = mainItems.map(item => item.arabicName);
      const englishNames = mainItems.map(item => item.englishName);
      setSelectionModel(
        items
          .map((_, index) => index + 1)
          .filter(
            index =>
              arabicNames.includes(items[index - 1].arabicName) &&
              englishNames.includes(items[index - 1].englishName)
          )
      );

      setLoading(false);
    }

    tryLocalSignin(history, '/main_items');
    fetchItemsData();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    const newMainItems = fetchedItems
      .filter((item, index) => selectionModel.includes(index + 1))
      .map(item => item._id);

    const res = await uploadMainItems(newMainItems);
    if (res.error) {
      setModalText('There is a problem in uploading Main Items List!');
    } else {
      setModalText('Main Items List uploaded successfully');
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  };

  const classes = useStyles();

  if (!token) {
    return null;
  }

  return (
    <div>
      <Container style={{ marginTop: '1%' }}>
        <Fade timeout={1000} in={true}>
          <div>
            <Typography className={classes.title}>Main Items</Typography>
            <Divider />

            <div style={{ height: 400, width: '100%', marginTop: '2%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                checkboxSelection
                disableSelectionOnClick
                disableColumnFilter
                loading={loading}
                onSelectionModelChange={setSelectionModel}
                selectionModel={selectionModel}
              />
            </div>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (!selectionModel.length) {
                  return;
                }

                setModalText('Do you want to submit this?');
                setShowConfirmButton(true);
                setModalVisible(true);
              }}
            >
              Submit
            </Button>
            <CustomModal
              title="Warning!"
              content={modalText}
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}
              onConfirm={handleSubmit}
              showConfirmButton={showConfirmButton}
              loading={loading}
            />
          </div>
        </Fade>
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em'
  },
  submitButton: {
    float: 'right',
    width: isMobileOnly ? '35%' : '10%',
    marginTop: '5%',
    marginBottom: '2%'
  }
});

export default MainItems;
