import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  Fade
} from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import { Context as BannerContext } from '../context/BannerContext';
import { Context as AuthContext } from '../context/AuthContext';
import CustomModal from '../components/CustomModal';

const Banner = ({ history }) => {
  const classes = useStyles();
  const [bannerImages, setBannerImages] = useState([]);
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [resetPhotos, setResetPhotos] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);
  const { uploadBannerImages } = useContext(BannerContext);

  useEffect(() => {
    tryLocalSignin(history, '/banner');
  }, []);

  const handleSubmit = async event => {
    setLoading(true);

    const res = await uploadBannerImages(bannerImages);

    if (res.error) {
      setModalText('There is a problem in uploading banner images!');
    } else {
      setModalText('Images uploaded successfully');
      setBannerImages([]);
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  };

  const validateImagesSizes = async event => {
    event.preventDefault();

    Object.values(event.target.files).forEach(imgFile => {
      if (imgFile.size > 1048576) {
        setModalText('There are images that exceed 1MB limit size');
        setModalVisible(true);
        setShowConfirmButton(false);
        setResetPhotos(true);
        return;
      }
    });

    setBannerImages(event.target.files);
  };

  if (!token) {
    return null;
  }

  return (
    <div>
      <Container style={{ marginTop: '1%' }}>
        <Fade timeout={1000} in={true}>
          <div>
            <Typography className={classes.title}>Banner Photos</Typography>
            <Divider />
            <Grid className={classes.container} container>
              <Grid direction="row" md={6} xs={12}>
                <Button
                  className={isMobileOnly ? classes.uploadButton : ''}
                  variant="contained"
                  component="label"
                  color="primary"
                >
                  Upload Banner Photos
                  <input
                    name="photos"
                    type="file"
                    id="files"
                    onChange={event => validateImagesSizes(event)}
                    hidden
                    multiple
                  />
                </Button>
                <Typography className={classes.uploadText}>
                  {bannerImages.length
                    ? `${bannerImages.length} image(s) uploaded`
                    : 'Please Upload Banner Photos'}
                </Typography>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (!bannerImages.length) {
                  return;
                }

                setModalText('Do you want to submit this?');
                setShowConfirmButton(true);
                setModalVisible(true);
                setResetPhotos(false);
              }}
            >
              Submit
            </Button>
            <CustomModal
              title="Warning!"
              content={modalText}
              visible={modalVisible}
              onCancel={() => {
                setModalVisible(false);
                if (resetPhotos) setBannerImages([]);
              }}
              onConfirm={handleSubmit}
              showConfirmButton={showConfirmButton}
              loading={loading}
            />
          </div>
        </Fade>
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em'
  },
  container: {
    marginTop: isMobileOnly ? '5%' : '2%'
  },
  submitButton: {
    float: 'right',
    width: isMobileOnly ? '35%' : '10%',
    marginTop: isMobileOnly ? '5%' : 0,
    marginBottom: '2%'
  }
});

export default Banner;
