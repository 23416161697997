import axios from 'axios';
import createDataContext from './createDataContext';
import { uploadNewImages, delete_images } from './commons';

const itemReducer = (state, action) => {
  switch (action.type) {
    case 'upload_item':
      return { ...state, errorMessage: '' };
    case 'fetch_items':
      return { ...state, items: action.payload };
    case 'edit_item':
      return { ...state };
    case 'fetch_main_items':
      return { ...state, errorMessage: '', mainItems: action.payload };
    case 'reset_items':
      return { ...state, errorMessage: '', items: [], mainItems: [] };
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const uploadMainItems = dispatch => async itemsIds => {
  try {
    await axios.post('/api/upload/mainitems', {
      itemsIds
    });
    return { error: '' };
  } catch (err) {
    console.log(err);
    dispatch({
      type: 'error',
      payload: 'Something went wrong while uploading main items list'
    });
    return { error: 'Error in uploading main items list' };
  }
};

const uploadItem = dispatch => async (itemDetails, subcategoryName) => {
  /*Item Main Image Config*/
  let itemMainImgConfig;
  try {
    itemMainImgConfig = await axios.get('/api/upload/img', {
      params: {
        path: `Subcategory/${subcategoryName.replace(/\s/g, '')}/Items`
      }
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: 'error',
      payload: 'Something went wrong while uploading item main image config'
    });
    return { error: 'Error in uploading item main image config' };
  }

  /*Upload item main Image to AWS*/
  try {
    await axios.put(itemMainImgConfig.data.url, itemDetails.mainImage, {
      headers: {
        'Content-Type': itemDetails.mainImage.type
      }
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: 'error',
      payload: 'Something went wrong while uploading item main image to AWS'
    });
    return { error: 'Error in Uploading item main image to AWS' };
  }

  /*Item Images Configs*/
  let imgsConfigs = [];
  try {
    for (let imgIndx = 0; imgIndx < itemDetails.images.length; imgIndx++) {
      imgsConfigs.push(
        await axios.get('/api/upload/img', {
          params: {
            path: `Subcategory/${subcategoryName.replace(/\s/g, '')}/Items`
          }
        })
      );
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: 'error',
      payload: 'Something went wrong while uploading item images config'
    });
    return { error: 'Error in uploading item images config' };
  }

  /*Upload Item Images to AWS*/
  try {
    for (let Index = 0; Index < imgsConfigs.length; Index++) {
      await axios.put(imgsConfigs[Index].data.url, itemDetails.images[Index], {
        headers: {
          'Content-Type': itemDetails.images[Index].type
        }
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: 'error',
      payload: 'Something went wrong while uploading item images to AWS'
    });
    return { error: 'Error in Uploading item images to AWS' };
  }

  /*nutrition Facts Images Configs*/
  let nutritionFactsImagesConfigs = [];
  if (itemDetails.nutritionFactsImages) {
    nutritionFactsImagesConfigs = [];
    try {
      for (
        let imgIndx = 0;
        imgIndx < itemDetails.nutritionFactsImages.length;
        imgIndx++
      ) {
        nutritionFactsImagesConfigs.push(
          await axios.get('/api/upload/img', {
            params: {
              path: `Subcategory/${subcategoryName.replace(/\s/g, '')}/Items`
            }
          })
        );
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: 'error',
        payload:
          'Something went wrong while uploading nutrition facts images config'
      });
      return { error: 'Error in uploading nutrition facts images config' };
    }

    /*Upload Item Images to AWS*/
    try {
      for (let Index = 0; Index < nutritionFactsImagesConfigs.length; Index++) {
        await axios.put(
          nutritionFactsImagesConfigs[Index].data.url,
          itemDetails.nutritionFactsImages[Index],
          {
            headers: {
              'Content-Type': itemDetails.nutritionFactsImages[Index].type
            }
          }
        );
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: 'error',
        payload:
          'Something went wrong while uploading nutrition facts images to AWS'
      });
      return { error: 'Error in Uploading nutrition facts images to AWS' };
    }
  }

  /*Upload Item to Database*/
  try {
    await axios.post('/api/upload/item', {
      ...itemDetails,
      mainImage: itemMainImgConfig.data.key,
      images: imgsConfigs.map(config => config.data.key),
      nutritionFactsImages: nutritionFactsImagesConfigs.map(
        config => config.data.key
      )
    });
    dispatch({ type: 'upload_item' });
    return { error: '' };
  } catch (err) {
    console.log(err);
    // TODO: delete recently Added Image
    dispatch({
      type: 'error',
      payload: 'Something went wrong while uploading item to the database'
    });
    return { error: 'Error in Uploading item to the database' };
  }
};

const fetchItems = dispatch => async subcategoryId => {
  try {
    const res = await axios.get('/api/data/items', {
      params: { subcategoryId }
    });
    dispatch({ type: 'fetch_items', payload: res.data });
    return { error: '', items: res.data };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while fetching items'
    });
    return { error: 'Error in Fetching items', items: [] };
  }
};

const fetchMainItems = dispatch => async () => {
  try {
    const res = await axios.get('/api/data/mainitems');
    dispatch({ type: 'fetch_main_items', payload: res.data });
    return { error: '', mainItems: res.data };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while fetching main items list'
    });
    return { error: 'Error in Fetching main items list', mainItems: [] };
  }
};

const editImages = async (
  images,
  selectedItemId,
  subcategoryName,
  imageKey,
  singleImage
) => {
  const upload_image_res = await uploadNewImages(
    images,
    `Subcategory/${subcategoryName.replace(/\s/g, '')}/Items`
  );
  if (upload_image_res.error) {
    return { error: upload_image_res.error };
  }

  try {
    let imagesPaths;
    if (singleImage) {
      imagesPaths = upload_image_res.imgsConfigs.map(
        config => config.data.key
      )[0];
    } else {
      imagesPaths = upload_image_res.imgsConfigs.map(config => config.data.key);
    }

    const edit_image_res = await axios.post('/api/edit/item', {
      itemId: selectedItemId,
      editedFields: {
        [imageKey]: imagesPaths
      }
    });

    const delete_res = await delete_images([
      edit_image_res.data.item[imageKey]
    ]);

    if (delete_res.error) {
      return { error: delete_res.error };
    } else {
      return { error: '' };
    }
  } catch (err) {
    console.log(err);
    return { error: 'Error in Editing the item' };
  }
};

const editItem =
  dispatch =>
  async (itemDetails, itemDetailsImages, selectedItemId, subcategoryName) => {
    const { mainImage, images, nutritionFactsImages } = itemDetailsImages;

    /*Main Image*/
    if (mainImage) {
      const res = editImages(
        [mainImage],
        selectedItemId,
        subcategoryName,
        'mainImage',
        true
      );
      if (res.error) {
        dispatch({
          type: 'error',
          payload: res.error
        });
        return { error: res.error };
      }
    }

    /*Item Images*/
    if (images && images.length) {
      const res = editImages(
        images,
        selectedItemId,
        subcategoryName,
        'images',
        false
      );
      if (res.error) {
        dispatch({
          type: 'error',
          payload: res.error
        });
        return { error: res.error };
      }
    }

    /*nutrition Facts Images*/
    if (nutritionFactsImages && nutritionFactsImages.length) {
      const res = editImages(
        nutritionFactsImages,
        selectedItemId,
        subcategoryName,
        'nutritionFactsImages',
        false
      );
      if (res.error) {
        dispatch({
          type: 'error',
          payload: res.error
        });
        return { error: res.error };
      }
    }

    /*Upload updates to database*/
    try {
      await axios.post('/api/edit/item', {
        itemId: selectedItemId,
        editedFields: itemDetails
      });
      dispatch({ type: 'edit_item' });
      return { error: '' };
    } catch {
      dispatch({
        type: 'error',
        payload: 'Something went wrong while uploading item to the database'
      });
      return { error: 'Error in Uploading item to the database' };
    }
  };

const deleteItem = dispatch => async selectedItemId => {
  try {
    const res = await axios.post('/api/delete/item', {
      itemId: selectedItemId
    });

    delete_images([
      res.data.item.mainImage,
      ...res.data.item.images,
      ...res.data.item.nutritionFactsImages
    ]);

    dispatch({ type: 'delete_item' });
    return { error: '' };
  } catch (err) {
    console.log(err);
    dispatch({
      type: 'error',
      payload: 'Something went wrong while deleting the item from the database'
    });
    return { error: 'Error in deleting the item from the database' };
  }
};

const search = dispatch => async searchString => {
  try {
    const res = await axios.get('/api/data/search', {
      params: { searchString }
    });

    dispatch({ type: 'search', payload: res.data });
    return { error: '', searchItems: res.data };
  } catch (err) {
    console.log(err);
    dispatch({
      type: 'error',
      payload: 'Something went wrong while searching'
    });
    return { error: 'Error in searching', searchItems: [] };
  }
};

const resetItemsState = dispatch => () => {
  dispatch({ type: 'reset_items' });
};

export const { Context, Provider } = createDataContext(
  itemReducer,
  {
    uploadMainItems,
    uploadItem,
    fetchItems,
    fetchMainItems,
    editItem,
    deleteItem,
    search,
    resetItemsState
  },
  { items: [], mainItems: [], errorMessage: '' }
);
