import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Typography,
  Divider,
  Grid,
  Container,
  ButtonBase,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Box
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import Aos from 'aos';
import 'aos/dist/aos.css';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import Slider from '../components/Carousel';
import { isMobileOnly } from 'react-device-detect';
import CustomModal from '../components/CustomModal';
import { Context as BannerContext } from '../context/BannerContext';
import { Context as LanguageContext } from '../context/LanguageContext';
import { Context as ArticleContext } from '../context/ArticleContext';
import { Context as ItemContext } from '../context/ItemContext';
import { Context as CartContext } from '../context/CartContext';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;
const AWS_URL = 'https://azka-bucket.s3.eu-central-1.amazonaws.com/';

const HomeScreen = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cartItem, setCartItem] = useState(null);
  const [modalText, setModalText] = useState('');
  const [showConfirmButton, setShowConfirmButton] = useState(false);

  const {
    fetchBannerImages,
    state: { images: bannerImages }
  } = useContext(BannerContext);

  const {
    fetchArticles,
    state: { articles }
  } = useContext(ArticleContext);

  const {
    fetchMainItems,
    state: { mainItems }
  } = useContext(ItemContext);

  const {
    state: { language }
  } = useContext(LanguageContext);

  const { addToCart } = useContext(CartContext);

  const props = {
    language
  };

  useEffect(() => {
    Aos.init({
      duration: 2000
    });

    async function fetchData() {
      setLoading(true);
      await fetchBannerImages();
      await fetchArticles();
      await fetchMainItems();
      setLoading(false);
    }
    fetchData();
  }, []);

  const handleAddingToCart = async () => {
    setLoading(true);
    const { englishName, arabicName, price, mainImage, _id } = cartItem;
    await addToCart({
      itemId: _id,
      itemName: englishName,
      arabicName,
      mainImage,
      price,
      amount: 1
    });

    setModalText(
      language === 'en' ? 'Item Added To Cart' : 'تمت الإضافة إلى السلة'
    );
    setShowConfirmButton(false);
    setModalVisible(true);
    setCartItem(null);
    setLoading(false);
  };

  const classes = useStyles(props);
  const history = useHistory();

  return (
    <div>
      <NavBar />
      {/* <div className={classes.toolbar}></div> */}
      {loading ? (
        <>
          <Skeleton
            variant="rect"
            //width={windowWidth}
            height={isMobileOnly ? windowHeight / 3.5 : windowHeight / 1.1}
          />
          <img
            src={require('../images/logo_admin.png').default}
            alt="logo"
            style={{
              objectFit: 'contain',
              height: isMobileOnly ? windowHeight / 8 : windowHeight / 2,
              cursor: 'pointer',
              position: 'absolute',
              top: isMobileOnly ? '17%' : '30%',
              left: '40%'
            }}
          />
        </>
      ) : (
        <Slider
          images={bannerImages}
          carouselImage={classes.carouselImage}
          carouselImageMobile={isMobileOnly ? classes.carouselImageMobile : ''}
          carouselimg={isMobileOnly ? classes.carouselimg : ''}
          showShopNowButton
        />
      )}
      <Container>
        <Typography
          variant="h2"
          data-aos="fade-right"
          className={classes.azkaText}
        >
          {language === 'en' ? 'AZKA Sweets' : 'حلويات أزكى'}
        </Typography>
        <Divider className={classes.divider} variant="middle" />
        <Typography
          data-aos="fade-right"
          variant="h5"
          className={classes.healthySentence}
        >
          {language === 'en'
            ? "It's easy to get hold of vegan chocolate from your health food shop"
            : 'من السهل الحصول على شوكولاتة نباتية من متجر الأطعمة الصحية الخاص بك'}
        </Typography>

        <Typography
          data-aos="fade-down"
          variant="h2"
          className={classes.standText}
        >
          {language === 'en' ? 'What do we stand out?' : 'ما الذي نتميز به؟'}
        </Typography>
        <Divider className={classes.divider} variant="middle" />
        <div
          data-aos="fade-down"
          className={`row ${classes.standImageContainer}`}
        >
          <Grid item md={3} xs={3} className={classes.standImageContainer}>
            <img
              src={require('../images/keto.jpeg').default}
              alt="keto"
              className={isMobileOnly ? classes.standImages : classes.standImagesWeb}
            />
          </Grid>
          <Grid item md={3} xs={3} className={classes.standImageContainer}>
            <img
              src={require('../images/gluten.jpeg').default}
              alt="gluten"
              className={isMobileOnly ? classes.standImages : classes.standImagesWeb}
            />
          </Grid>
          <Grid item md={3} xs={3} className={classes.standImageContainer}>
            <img
              src={require('../images/hand_made.jpeg').default}
              alt="hand_made"
              className={isMobileOnly ? classes.standImages : classes.standImagesWeb}
            />
          </Grid>
          <Grid item md={3} xs={3} className={classes.standImageContainer}>
            <img
              src={require('../images/originic.jpeg').default}
              alt="originic"
              className={isMobileOnly ? classes.standImages : classes.standImagesWeb}
            />
          </Grid>
          <Grid item md={3} xs={3} className={classes.standImageContainer}>
            <img
              src={require('../images/trans_fats_free.jpeg').default}
              alt="trans_fats_free"
              className={isMobileOnly ? classes.standImages : classes.standImagesWeb}
            />
          </Grid>
          <Grid item md={3} xs={3} className={classes.standImageContainer}>
            <img
              src={require('../images/lactose.jpeg').default}
              alt="lactose"
              className={isMobileOnly ? classes.standImages : classes.standImagesWeb}
            />
          </Grid>
          <Grid item md={3} xs={3} className={classes.standImageContainer}>
            <img
              src={require('../images/natural.jpeg').default}
              alt="natural"
              className={isMobileOnly ? classes.standImages : classes.standImagesWeb}
            />
          </Grid>
          <Grid item md={3} xs={3} className={classes.standImageContainer}>
            <img
              src={require('../images/sugar.jpeg').default}
              alt="sugar"
              className={isMobileOnly ? classes.standImages : classes.standImagesWeb}
            />
          </Grid>
          <Grid item md={3} xs={3} className={classes.standImageContainer}>
            <img
              src={require('../images/low_carbs.jpeg').default}
              alt="low_carbs"
              className={isMobileOnly ? classes.standImages : classes.standImagesWeb}
            />
          </Grid>
          <Grid item md={3} xs={3} className={classes.standImageContainer}>
            <img
              src={require('../images/egg.jpeg').default}
              alt="egg"
              className={isMobileOnly ? classes.standImages : classes.standImagesWeb}
            />
          </Grid>
          <Grid item md={3} xs={3} className={classes.standImageContainer}>
            <img
              src={require('../images/diary.jpeg').default}
              alt="diary"
              className={isMobileOnly ? classes.standImages : classes.standImagesWeb}
            />
          </Grid>
          <Grid item md={3} xs={3} className={classes.standImageContainer}>
            <img
              src={require('../images/cholesterol.jpeg').default}
              alt="cholesterol"
              className={isMobileOnly ? classes.standImages : classes.standImagesWeb}
            />
          </Grid>
          <Grid item md={3} xs={3} className={classes.standImageContainer}>
            <img
              src={require('../images/caffeine_free.jpeg').default}
              alt="caffeine_free"
              className={isMobileOnly ? classes.standImages : classes.standImagesWeb}
            />
          </Grid>
          <Grid item md={3} xs={3} className={classes.standImageContainer}>
            <img
              src={require('../images/vegan.jpeg').default}
              alt="vegan"
              className={isMobileOnly ? classes.standImages : classes.standImagesWeb}
            />
          </Grid>
          <Grid item md={3} xs={3} className={classes.standImageContainer}>
            <img
              src={require('../images/alcohol_free.jpeg').default}
              alt="alcohol_free"
              className={isMobileOnly ? classes.standImages : classes.standImagesWeb}
            />
          </Grid>
          <Grid item md={3} xs={3} className={classes.standImageContainer}>
            <img
              src={require('../images/soy_free.jpeg').default}
              alt="soy_free"
              className={isMobileOnly ? classes.standImages : classes.standImagesWeb}
            />
          </Grid>
        </div>
        <Typography
          data-aos="fade-down"
          variant="h2"
          className={classes.checkText}
        >
          {language === 'en'
            ? 'Check out our healthy products'
            : 'بعض منتجاتنا الصحية'}
        </Typography>
        <Divider className={classes.divider} variant="middle" />

        <div data-aos="fade-right" className={classes.root}>
          {loading &&
            [1, 2, 3, 4].map(x => (
              <>
                <Grid
                  item
                  md={3}
                  xs={6}
                  className={classes.standImageContainer}
                >
                  <ButtonBase
                    focusRipple
                    key={x}
                    className={classes.image}
                    focusVisibleClassName={classes.focusVisible}
                    style={{
                      width: '90%',
                      marginBottom: '5%',
                      marginInline: '2%'
                    }}
                  >
                    <Skeleton
                      variant="rect"
                      width={isMobileOnly ? windowWidth / 2.5 : '100%'}
                      height={
                        isMobileOnly ? windowHeight / 2.5 : windowHeight / 2
                      }
                      animation="wave"
                      classes={{ root: classes.skeleton }}
                    />
                    <img
                      src={require('../images/logo_admin.png').default}
                      alt="logo"
                      style={{
                        objectFit: 'contain',
                        height: windowHeight / 8,
                        cursor: 'pointer',
                        position: 'absolute',
                        top: isMobileOnly ? '20%' : '40%'
                      }}
                    />
                  </ButtonBase>
                </Grid>
              </>
            ))}
          {mainItems.map(item => (
            <Grid item md={3} xs={6} className={classes.standImageContainer}>
              <ButtonBase
                focusRipple
                key={item._id}
                className={classes.image}
                focusVisibleClassName={classes.focusVisible}
                style={{
                  width: '90%',
                  marginBottom: '5%',
                  marginInline: '2%'
                }}
                onClick={() =>
                  history.push({
                    pathname: '/detailditem',
                    state: { item }
                  })
                }
              >
                <>
                  <span
                    className={classes.imageSrc}
                    style={{
                      backgroundImage: `url(${AWS_URL + item.mainImage})`
                    }}
                  />
                  <span className={classes.imageBackdrop} />
                  <span className={classes.imageButton}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="inherit"
                      className={classes.imageTitle}
                    >
                      {language === 'en' ? item.englishName : item.arabicName}
                      <span className={classes.imageMarked} />
                    </Typography>
                    <Typography className={classes.price}>
                      {language === 'en' ? 'Price:' : 'السعر:'} {item.price}{' '}
                      {language === 'en' ? 'JD' : 'دينار'}
                    </Typography>
                  </span>
                </>
              </ButtonBase>
              <Button
                variant="contained"
                color="primary"
                className={classes.addCartButton}
                onClick={() => {
                  setCartItem(item);
                  setModalText(
                    language === 'en'
                      ? `Do you want to add ${item.englishName} to the cart?`
                      : `هل ترغب بإضافة ${item.arabicName} إلى السلة؟`
                  );
                  setShowConfirmButton(true);
                  setModalVisible(true);
                }}
              >
                {language === 'en' ? 'Add to Cart' : 'أضف إلى السلة'}
              </Button>
              <CustomModal
                visible={modalVisible}
                title={
                  language === 'en' ? 'Adding to Cart' : 'الإضافة إلى السلة'
                }
                loading={false}
                content={modalText}
                showConfirmButton={showConfirmButton}
                onCancel={() => setModalVisible(false)}
                onConfirm={handleAddingToCart}
              />
            </Grid>
          ))}
        </div>

        <Typography
          data-aos="fade-down"
          variant="h2"
          className={classes.checkText}
        >
          {language === 'en' ? 'Healthy News' : 'الأخبار الصحية'}
        </Typography>
        <Divider className={classes.divider} variant="middle" />

        <div data-aos="fade-right" className={classes.root}>
          {loading &&
            [1, 2, 3, 4].map(x => (
              <Grid item md={3} xs={6} className={classes.standImageContainer}>
                <Card className={classes.cardContainer}>
                  <CardActionArea>
                    <Box flexDirection="column" style={{ marginBottom: '6%' }}>
                      <Skeleton
                        variant="rect"
                        width={
                          isMobileOnly ? windowWidth / 2.5 : windowWidth / 7
                        }
                        height={windowHeight / 4}
                      />
                      <Skeleton width="80%" />
                      <Skeleton width="60%" />
                    </Box>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          {articles.map(
            ({ image, arabicTitle, englishTitle, arabicBody, englishBody }) => (
              <Grid item md={3} xs={6} className={classes.standImageContainer}>
                <Card className={classes.cardContainer}>
                  <CardActionArea
                    onClick={() =>
                      history.push({
                        pathname: '/article',
                        state: {
                          image,
                          arabicTitle,
                          englishTitle,
                          arabicBody,
                          englishBody
                        }
                      })
                    }
                  >
                    <CardMedia
                      className={classes.media}
                      image={AWS_URL + image}
                      title="Contemplative Reptile"
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {language === 'en' ? englishTitle : arabicTitle}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {language === 'en'
                          ? englishBody.split('\n')[0].slice(0, 35) + '...'
                          : '...' + arabicBody.split('\n')[0].slice(0, 30)}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      className={classes.learnText}
                      variant="contained"
                      onClick={() =>
                        history.push({
                          pathname: '/article',
                          state: {
                            image,
                            arabicTitle,
                            englishTitle,
                            arabicBody,
                            englishBody
                          }
                        })
                      }
                    >
                      {language === 'en' ? 'Learn more' : '...المزيد'}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )
          )}
        </div>
      </Container>
      <Footer />
    </div>
  );
};

const useStyles = makeStyles(theme => {
  return {
    coverImage: {
      width: windowWidth,
      objectFit: 'cover',
      height: windowHeight
    },
    skeleton: {
      marginBottom: '2%'
    },
    carousel: {
      //height: height / 1.15,
      width: '100%'
    },
    carouselImage: {
      height: windowHeight / 1.1
    },
    carouselImageMobile: {
      height: windowHeight / 3
    },
    carouselimg: {
      objectFit: 'contain',
      height: windowHeight / 3,
      width: windowWidth
    },
    shopButton: {
      position: 'absolute',
      top: '75%',
      //left: '47%',
      zIndex: 222,
      width: windowWidth / 6,
      alignSelf: 'center',
      backgroundColor: '#92c83e',
      color: 'white',
      '&:hover': {
        backgroundColor: '#92c83e'
      }
    },
    azkaText: {
      textAlign: 'center',
      marginTop: '2%',
      color: '#92c83e',
      fontSize: isMobileOnly ? '2rem' : '2.5rem'
    },
    divider: {
      color: 'red',
      marginBottom: '2%'
      //width: windowWidth / 2
    },
    healthySentence: {
      textAlign: 'center',
      fontSize: isMobileOnly ? '1rem' : '1.5rem'
    },
    standText: {
      textAlign: 'center',
      marginTop: '10%',
      color: '#92c83e',
      fontSize: isMobileOnly ? '2rem' : '2.5rem'
    },
    standImages: {
      objectFit: 'contain',
      width: windowWidth / 4,
      alignSelf: 'center'
    },
    standImagesWeb: {
      objectFit: 'contain',
      width: windowWidth / 8,
      alignSelf: 'center'
    },
    standImageContainer: {
      display: 'flex',
      justifyContent: 'center'
    },
    checkText: {
      textAlign: 'center',
      marginTop: '10%',
      color: '#92c83e',
      fontSize: isMobileOnly ? '2rem' : '2.5rem'
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      minWidth: 300,
      width: '100%'
    },
    image: {
      position: 'relative',
      height: windowHeight / 2,
      boxShadow: '4px 4px 4px 4px rgba(0,0,0,.2)',
      [theme.breakpoints.down('xs')]: {
        width: '100% !important', // Overrides inline-style
        height: windowHeight / 3
      },
      '&:hover, &$focusVisible': {
        zIndex: 1,
        '& $imageBackdrop': {
          opacity: 0.15
        },
        '& $imageMarked': {
          opacity: 0
        },
        '& $imageTitle': {
          border: '4px solid currentColor'
        }
      }
    },
    focusVisible: {},
    imageButton: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.common.white,
      flexDirection: 'column'
    },
    imageSrc: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundPosition: 'center 40%'
    },
    imageBackdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.4,
      transition: theme.transitions.create('opacity')
    },
    imageTitle: {
      position: 'relative',
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6
        }px`
    },
    imageMarked: {
      height: 3,
      width: 18,
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      bottom: -2,
      left: 'calc(50% - 9px)',
      transition: theme.transitions.create('opacity')
    },
    price: {
      color: 'white',
      top: isMobileOnly ? '65%' : '60%',
      position: 'absolute',
      fontWeight: 'bold',
      fontSize: '1.1em'
    },
    addCartButton: {
      top: isMobileOnly ? '38%' : '80%',
      backgroundColor: '#92c83e',
      position: 'absolute',
      zIndex: 222,
      color: 'white',
      '&:hover': {
        backgroundColor: '#92c83e',
        color: 'white'
      }
    },
    cardContainer: {
      marginInline: '2%',
      marginBottom: '5%'
    },
    media: {
      height: windowHeight / 5,
      width: isMobileOnly ? windowWidth / 2 : windowWidth / 4
    },
    learnText: {
      backgroundColor: '#92c83e',
      color: 'white',
      '&:hover': {
        backgroundColor: '#92c83e',
        color: 'white'
      }
    },
    footerContainer: {
      backgroundColor: '#92c83e',
      height: windowHeight / 4
    },
    contactTitle: {
      color: 'white',
      fontSize: '1.5em',
      marginTop: '2%'
    },
    contactDetails: {
      color: 'white',
      fontSize: '1em',
      marginTop: '2%'
    },
    cardContent: {
      whiteSpace: 'pre-line',
      wordWrap: 'break-word',
      textAlign: ({ language }) => (language === 'en' ? 'left' : 'right')
    }
  };
});

export default HomeScreen;
