import React, { useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Container,
  Divider,
  Button,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { Context as LanguageContext } from '../context/LanguageContext';
import { isMobileOnly } from 'react-device-detect';

const CustomModal = ({
  title,
  content,
  onConfirm,
  visible,
  onCancel,
  showConfirmButton,
  loading
}) => {
  const {
    state: { language }
  } = useContext(LanguageContext);

  const props = {
    language
  };

  const classes = useStyles(props);
  return (
    <Dialog
      open={visible}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ style: { width: isMobileOnly ? '100%' : '70%' } }}
    >
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <Container>
        <Divider />
      </Container>
      <DialogContent>
        <DialogContentText
          className={classes.content}
          id="alert-dialog-slide-description"
        >
          {content}
        </DialogContentText>
      </DialogContent>

      {loading && (
        <CircularProgress
          style={{
            position: 'absolute',
            alignSelf: 'center',
            marginTop: '16%'
          }}
        />
      )}

      <DialogActions className={classes.button}>
        {language === 'en' ? (
          <>
            <Button color="secondary" onClick={onCancel}>
              Cancel
            </Button>
            {showConfirmButton && (
              <Button color="primary" onClick={onConfirm}>
                Confirm
              </Button>
            )}
          </>
        ) : (
          <>
            {showConfirmButton && (
              <Button color="primary" onClick={onConfirm}>
                نعم
              </Button>
            )}
            <Button color="secondary" onClick={onCancel}>
              إغلاق
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  title: {
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right')
  },
  content: {
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right'),
    whiteSpace: 'pre-line'
  },
  button: {
    justifyContent: ({ language }) =>
      language === 'en' ? 'flex-end' : 'flex-start'
  }
});

export default CustomModal;
