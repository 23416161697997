import React, { useState, useContext, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  TextField,
  Fade,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import CustomModal from '../../components/CustomModal';
import { Context as AuthContext } from '../../context/AuthContext';
import { Context as CategoryContext } from '../../context/CategoryContext';

const EditSubCategory = ({ history }) => {
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [newArabicName, setNewArabicName] = useState('');
  const [newEnglishName, setNewEnglishName] = useState('');
  const [newSubCategoryImage, setNewSubCategoryImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [modalText, setModalText] = useState('');
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [resetPhoto, setResetPhoto] = useState(false);

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);
  const {
    fetchCategories,
    fetchSubCategories,
    editSubCategory,
    state: { categories, subcategories }
  } = useContext(CategoryContext);

  useEffect(() => {
    async function fetchCategoriesData() {
      setLoading(true);
      await fetchCategories();
      setLoading(false);
    }

    tryLocalSignin(history, '/edit_subcategory');
    fetchCategoriesData();
  }, []);

  const validateImagesSizes = async event => {
    event.preventDefault();

    Object.values(event.target.files).forEach(imgFile => {
      if (imgFile.size > 1048576) {
        setModalText('The image exceeds 1MB limit size');
        setModalVisible(true);
        setShowConfirmButton(false);
        setResetPhoto(true);
        return;
      }
    });

    setNewSubCategoryImage(event.target.files[0]);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const res = await editSubCategory(
      selectedSubCategoryId,
      newArabicName,
      newEnglishName,
      newSubCategoryImage
    );

    if (res.error) {
      setModalText('There is a problem in editing the subcategory!');
    } else {
      setModalText('Subcategory edited successfully');
      setSelectedSubCategoryId(null);
      setNewArabicName('');
      setNewEnglishName('');
      setNewSubCategoryImage(null);
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  };

  const classes = useStyles();

  if (!token) {
    return null;
  }

  return (
    <div>
      <Container style={{ marginTop: '1%' }}>
        {loading && (
          <CircularProgress
            style={{
              display: 'flex',
              position: 'absolute',
              alignSelf: 'center',
              top: '50%',
              left: '50%'
            }}
          />
        )}
        <Fade timeout={1000} in={true}>
          <div>
            <Typography className={classes.title}>Edit Subcategory</Typography>
            <Divider />
            <Grid
              className={
                isMobileOnly ? classes.mobileContainer : classes.container
              }
              container
            >
              <Grid md={6} xs={12}>
                <Typography className={classes.chooseText}>
                  Choose Category
                </Typography>
                <Select
                  placeholder="Choose category"
                  variant="outlined"
                  className={classes.selectCategory}
                  onChange={async event => {
                    setNewArabicName('');
                    setNewEnglishName('');
                    setSelectedSubCategoryId(null);
                    setLoading(true);
                    await fetchSubCategories(event.target.value);
                    setLoading(false);
                  }}
                >
                  {categories.map(category => (
                    <MenuItem value={category._id}>
                      {category.englishName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid md={6} xs={12}>
                <Typography className={classes.chooseText}>
                  Choose Subcategory
                </Typography>
                <Select
                  placeholder="Choose Subcategory"
                  variant="outlined"
                  className={classes.selectCategory}
                  onChange={event => {
                    setSelectedSubCategoryId(event.target.value._id);
                    setNewArabicName(event.target.value.arabicName);
                    setNewEnglishName(event.target.value.englishName);
                  }}
                >
                  {subcategories.map(subcategory => (
                    <MenuItem value={subcategory}>
                      {subcategory.englishName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid md={6} xs={12}>
                <TextField
                  value={newEnglishName}
                  onChange={e => setNewEnglishName(e.target.value)}
                  placeholder="New English Subcategory Name"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid md={6} xs={12}>
                <TextField
                  value={newArabicName}
                  onChange={e => setNewArabicName(e.target.value)}
                  placeholder="New Arabic Subcategory Name"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid direction="row" md={6} xs={12}>
                <Button
                  className={classes.uploadButton}
                  variant="contained"
                  component="label"
                  color="primary"
                >
                  Upload New Photo
                  <input
                    name="photos"
                    type="file"
                    id="files"
                    onChange={event => validateImagesSizes(event)}
                    hidden
                  />
                </Button>
                <Typography variant="h6">
                  {newSubCategoryImage
                    ? 'New Photo Selected'
                    : 'Please Select New Photo'}
                </Typography>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (
                  !selectedSubCategoryId ||
                  !newEnglishName ||
                  !newArabicName
                ) {
                  return;
                }

                setModalText('Do you want to submit this?');
                setShowConfirmButton(true);
                setModalVisible(true);
                setResetPhoto(false);
              }}
            >
              Submit
            </Button>
            <CustomModal
              title="Warning!"
              content={modalText}
              visible={modalVisible}
              onCancel={() => {
                setModalVisible(false);
                if (resetPhoto) setNewSubCategoryImage(null);
              }}
              onConfirm={handleSubmit}
              showConfirmButton={showConfirmButton}
              loading={loading}
            />
          </div>
        </Fade>
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em'
  },
  container: {
    marginTop: '2%'
  },
  mobileContainer: {
    marginTop: '5%'
  },
  chooseText: {
    fontSize: '1.3em',
    marginBottom: '1%'
  },
  uploadButton: {
    marginTop: '5%'
  },
  submitButton: {
    float: 'right',
    width: isMobileOnly ? '35%' : '10%',
    marginTop: isMobileOnly ? '5%' : 0,
    marginBottom: '2%'
  },
  selectCategory: {
    width: isMobileOnly ? '85%' : '50%',
    marginBottom: '5%'
  },
  textField: {
    marginTop: isMobileOnly ? '5%' : 0,
    width: isMobileOnly ? '85%' : '50%'
  }
});

export default EditSubCategory;
