import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  Typography,
  Divider,
  Container,
  Grid,
  ButtonBase,
  CircularProgress
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { isMobileOnly } from 'react-device-detect';
import Aos from 'aos';
import 'aos/dist/aos.css';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { Context as CategoryContext } from '../context/CategoryContext';
import { Context as LanguageContext } from '../context/LanguageContext';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;
const AWS_URL = 'https://azka-bucket.s3.eu-central-1.amazonaws.com/';

const CategoriesScreen = () => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const {
    fetchCategories,
    state: { categories }
  } = useContext(CategoryContext);

  const {
    state: { language }
  } = useContext(LanguageContext);

  const props = {
    language
  };

  useEffect(() => {
    Aos.init({
      duration: 2000
    });

    async function fetchData() {
      setLoading(true);
      await fetchCategories();
      setLoading(false);
    }

    fetchData();
  }, []);

  const classes = useStyles(props);
  return (
    <div>
      <NavBar />
      <div className={classes.toolbar}></div>
      <Container>
        {/* {loading && (
          <CircularProgress
            style={{
              display: 'flex',
              position: 'absolute',
              alignSelf: 'center',
              top: '50%',
              left: '50%',
              color: '#92c83e'
            }}
          />
        )} */}
        <Typography data-aos="fade-down" variant="h2" className={classes.title}>
          {language === 'en' ? 'Checkout our collection' : 'أصناف الحلويات'}
        </Typography>
        <Divider />
        <div data-aos="fade-right" className={classes.root}>
          {loading &&
            [1, 2, 3, 4].map(x => (
              <Grid item md={3} xs={6} className={classes.standImageContainer}>
                <ButtonBase
                  focusRipple
                  key={x}
                  className={classes.image}
                  focusVisibleClassName={classes.focusVisible}
                  style={{
                    width: '90%',
                    marginBottom: '5%',
                    marginInline: '2%'
                  }}
                >
                  <Skeleton
                    variant="rect"
                    width={isMobileOnly ? windowWidth / 2 : windowWidth}
                    height={isMobileOnly ? windowHeight / 3 : windowHeight / 2}
                  />
                  <img
                    src={require('../images/logo_admin.png').default}
                    alt="logo"
                    style={{
                      objectFit: 'contain',
                      height: windowHeight / 8,
                      cursor: 'pointer',
                      position: 'absolute',
                      top: isMobileOnly ? '20%' : '40%'
                    }}
                  />
                </ButtonBase>
              </Grid>
            ))}
          {categories.map(category => (
            <Grid item md={3} xs={6} className={classes.standImageContainer}>
              <ButtonBase
                focusRipple
                key={category.englishName}
                className={classes.image}
                focusVisibleClassName={classes.focusVisible}
                style={{
                  width: '90%',
                  marginBottom: '5%',
                  marginInline: '2%'
                }}
                onClick={() =>
                  history.push({
                    pathname: '/subcatagory',
                    state: { categoryId: category._id }
                  })
                }
              >
                <>
                  <span
                    className={classes.imageSrc}
                    style={{
                      backgroundImage: `url(${AWS_URL + category.image})`
                    }}
                  />
                  <span className={classes.imageBackdrop} />
                  <span className={classes.imageButton}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="inherit"
                      className={classes.imageTitle}
                    >
                      {language === 'en'
                        ? category.englishName
                        : category.arabicName}
                      <span className={classes.imageMarked} />
                    </Typography>
                  </span>
                </>
              </ButtonBase>
            </Grid>
          ))}
        </div>
      </Container>
      <Footer />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  title: {
    textAlign: 'center',
    //marginTop: '7%',
    color: '#92c83e',
    fontSize: isMobileOnly ? '1.8em' : '2.5em'
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
    marginTop: '2%'
  },
  standImageContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    position: 'relative',
    height: windowHeight / 2,
    boxShadow: '4px 4px 4px 4px rgba(0,0,0,.2)',
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: windowHeight / 3
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15
      },
      '& $imageMarked': {
        opacity: 0
      },
      '& $imageTitle': {
        border: '4px solid currentColor'
      }
    }
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    flexDirection: 'column'
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%'
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity')
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity')
  }
}));

export default CategoriesScreen;
