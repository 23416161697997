import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Card,
  CardActions,
  CardActionArea,
  CardMedia,
  CardContent,
  Container,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { isMobileOnly } from 'react-device-detect';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import CustomModal from '../components/CustomModal';
import { Context as LanguageContext } from '../context/LanguageContext';
import { Context as CartContext } from '../context/CartContext';
import { Context as ItemContext } from '../context/ItemContext';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;
const AWS_URL = 'https://azka-bucket.s3.eu-central-1.amazonaws.com/';

const SearchScreen = ({ history }) => {
  const location = useLocation();
  let searchText;
  try {
    searchText = location.state.searchText;
  } catch {
    history.push('/');
  }

  const [items, setItems] = useState([]);
  const [cartItem, setCartItem] = useState(null);
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    state: { language }
  } = useContext(LanguageContext);
  const { addToCart } = useContext(CartContext);
  const { search } = useContext(ItemContext);

  const props = {
    language
  };

  useEffect(() => {
    async function searchDB() {
      setLoading(true);
      const { searchItems } = await search(searchText);
      setItems(searchItems);
      setLoading(false);
    }

    if (searchText) searchDB();
  }, [searchText]);

  const classes = useStyles(props);

  const handleAddingToCart = async () => {
    setLoading(true);
    const { englishName, arabicName, price, mainImage, _id } = cartItem;
    await addToCart({
      itemId: _id,
      itemName: englishName,
      arabicName,
      mainImage,
      price,
      amount: 1
    });

    setModalText(
      language === 'en' ? 'Item Added To Cart' : 'تمت الإضافة إلى السلة'
    );
    setShowConfirmButton(false);
    setModalVisible(true);
    setCartItem(null);
    setLoading(false);
  };

  return (
    <div>
      <NavBar />
      <div className={classes.toolbar}></div>
      <Container>
        <Typography className={classes.title}>
          {language === 'en'
            ? `Search Results for "${searchText}"`
            : `نتائج البحث لـ ${searchText} `}
        </Typography>
        <hr />
        <div data-aos="fade-right" className={classes.root}>
          {items.map(item => (
            <Grid
              key={item._id}
              item
              md={3}
              xs={6}
              className={classes.standImageContainer}
            >
              <Card className={classes.cardContainer}>
                {loading ? (
                  <Skeleton
                    variant="rect"
                    width={windowWidth / 2}
                    height={isMobileOnly ? windowHeight / 3.5 : windowHeight}
                  />
                ) : (
                  <CardActionArea
                    onClick={() =>
                      history.push({
                        pathname: '/detailditem',
                        state: { item }
                      })
                    }
                    className={classes.cardActionArea}
                  >
                    <CardMedia
                      className={
                        isMobileOnly
                          ? classes.mobileCardImage
                          : classes.cardImage
                      }
                      image={AWS_URL + item.mainImage}
                      title="Contemplative Reptile"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        align="center"
                        variant="h5"
                        component="h2"
                      >
                        {language === 'en' ? item.englishName : item.arabicName}
                      </Typography>
                      <Typography className={classes.price} align="center">
                        {language === 'en' ? 'Price:' : 'السعر:'}{' '}
                        <span style={{ color: 'red' }}>
                          {item.price} {language === 'en' ? 'JD' : 'دينار'}
                        </span>
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                )}
                <CardActions
                  style={{
                    justifyContent: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <Button
                    size="small"
                    className={classes.addToCart}
                    variant="contained"
                    onClick={() => {
                      setCartItem(item);
                      setModalText(
                        language === 'en'
                          ? `Do you want to add ${item.englishName} to the cart?`
                          : `هل ترغب بإضافة ${item.arabicName} إلى السلة؟`
                      );
                      setShowConfirmButton(true);
                      setModalVisible(true);
                    }}
                  >
                    {language === 'en' ? 'Add to Cart' : 'أضف إلى السلة'}
                  </Button>
                </CardActions>
              </Card>
              <CustomModal
                visible={modalVisible}
                title={
                  language === 'en' ? 'Adding to Cart' : 'الإضافة إلى السلة'
                }
                loading={loading}
                content={modalText}
                showConfirmButton={showConfirmButton}
                onCancel={() => setModalVisible(false)}
                onConfirm={handleAddingToCart}
              />
            </Grid>
          ))}
          {!loading && !items.length && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'center',
                flexDirection: 'column',
                width: windowWidth
              }}
            >
              <img
                src={require('../images/empty_search.gif').default}
                style={{
                  alignSelf: 'center',
                  objectFit: 'contain',
                  width: isMobileOnly ? windowWidth / 1.5 : null,
                  height: windowHeight / 2
                }}
              />
              <Typography variant="h5" className={classes.noResultText}>
                {language === 'en'
                  ? `There is no results about "${searchText}"`
                  : `لا توجد نتائج بحث حول ${searchText}`}
              </Typography>
            </div>
          )}
        </div>
      </Container>
      <Footer />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  title: {
    color: '#92c83e',
    fontSize: isMobileOnly ? '1.8em' : '2.5rem',
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right')
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: '2%'
  },
  standImageContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  cardContainer: {
    marginInline: '2%',
    marginBottom: '5%'
  },
  cardActionArea: {
    '&:hover': {
      color: 'black'
    }
  },
  cardImage: {
    height: windowHeight / 3,
    width: windowWidth / 6
  },
  mobileCardImage: {
    height: windowHeight / 3,
    width: windowWidth / 2
  },
  price: {
    fontWeight: 'bold'
  },
  addToCart: {
    backgroundColor: '#92c83e',
    color: 'white',
    '&:hover': {
      backgroundColor: '#92c83e',
      color: 'white'
    },
    alignSelf: 'center'
  },
  noResultText: {
    textAlign: 'center',
    marginBottom: '5%'
  }
}));

export default SearchScreen;
