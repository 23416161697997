import React, { useContext } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Context as LanguageContext } from '../context/LanguageContext';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const AWS_URL = 'https://azka-bucket.s3.eu-central-1.amazonaws.com/';

const AccordionExpand = ({ title, description, images }) => {
  const {
    state: { language }
  } = useContext(LanguageContext);

  const props = {
    language
  };

  const classes = useStyles(props);
  return (
    <Accordion className={classes.accordionContainer}>
      <AccordionSummary
        expandIcon={<ExpandMore className={classes.expandIcon} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordionTitleBackground}
      >
        <Typography className={classes.accordionTitle}>{title}</Typography>
      </AccordionSummary>

      <AccordionDetails style={{ flexDirection: 'column' }}>
        {images && (
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-around'
            }}
          >
            {images.map(image => (
              <img
                key={image}
                src={AWS_URL + image}
                style={{ height: windowHeight / 10 }}
              />
            ))}
          </div>
        )}
        <Typography className={classes.description}>{description}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles({
  accordionContainer: {
    width: '100%',
    marginTop: '3%',
    marginBottom: '2%',
    direction: ({ language }) => (language === 'en' ? 'ltr' : 'rtl')
  },
  accordionTitleBackground: {
    borderColor: '#92c83e'
  },
  accordionTitle: {
    color: '#92c83e'
  },
  expandIcon: {
    color: '#92c83e'
  },
  description: {
    whiteSpace: 'pre-line',
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right'),
    marginTop: '3%'
  }
});

export default AccordionExpand;
