import React, { useState, useContext, useEffect } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { makeStyles, InputBase, alpha, Badge, Box } from '@material-ui/core';
import { Search, ShoppingCart } from '@material-ui/icons';
import '../css/Navbar.css';
import { isMobileOnly } from 'react-device-detect';
import { Context as LanguageContext } from '../context/LanguageContext';
import { Context as CartContext } from '../context/CartContext';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const HomeScreen = () => {
  const [numItemsInCart, setNumItemsInCart] = useState(0);
  const [searchText, setSearchText] = useState('');
  const {
    state: { language },
    setLanguage
  } = useContext(LanguageContext);

  const props = {
    language
  };

  const {
    state: { cart }
  } = useContext(CartContext);

  const classes = useStyles(props);
  const history = useHistory();

  useEffect(() => {
    let numItems = 0;
    cart.forEach(item => (numItems += item.amount));
    setNumItemsInCart(numItems);
  }, [cart]);

  return (
    <>
      <Navbar
        style={{ paddingTop: 0 }}
        fixed="top"
        className={isMobileOnly ? classes.navmobile : classes.nav}
        expand="lg"
      >
        <Container className={classes.container}>
          <Navbar.Brand onClick={() => history.push('/')}>
            <img
              src={require('../images/logo.jpeg').default}
              alt="logo"
              style={{
                objectFit: 'contain',
                height: windowHeight / 10,
                cursor: 'pointer'
              }}
            />
          </Navbar.Brand>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <Search />
            </div>
            <InputBase
              placeholder={language === 'en' ? 'Search…' : '...البحث'}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'search' }}
              style={{ border: '2px solid #92c83e', borderRadius: 30 }}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              onKeyPress={ev => {
                if (ev.key === 'Enter' && searchText) {
                  history.push({ pathname: '/search', state: { searchText } });
                  ev.preventDefault();
                }
              }}
            />
          </div>
          <Nav.Link
            className={classes.cartIcon}
            onClick={() => history.push('/cart')}
          >
            <Badge badgeContent={numItemsInCart} color="secondary">
              <ShoppingCart />
            </Badge>
          </Nav.Link>
          <Navbar.Toggle className={classes.nav} aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              navbarScroll
              className={`navbar-nav ms-auto ${classes.navLinks}`}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: isMobileOnly ? 'column' : 'row',
                  direction: language === 'en' ? 'ltr' : 'rtl'
                }}
              >
                <Nav.Link
                  className={classes.home}
                  onClick={() => history.push('/')}
                >
                  {language === 'en' ? 'Home' : 'الرئيسة'}
                </Nav.Link>
                <Nav.Link
                  className={classes.home}
                  onClick={() => history.push('/about')}
                >
                  {language === 'en' ? 'About' : 'نبذة عنا'}
                </Nav.Link>
                <Nav.Link
                  className={classes.home}
                  onClick={() => history.push('/shop')}
                >
                  {language === 'en' ? 'Shop' : 'تسوق الآن'}
                </Nav.Link>
                <Nav.Link className={classes.home} href="#what's monkifrute">
                  {language === 'en' ? `What's monkfruite` : 'ما هي المونكفروت'}
                </Nav.Link>
                <Nav.Link className={classes.home} onClick={() => history.push('/contact')}>
                  {language === 'en' ? 'Contact us' : 'تواصل معنا'}
                </Nav.Link>
                <Nav.Link
                  className={classes.home}
                  onClick={() => setLanguage(language === 'en' ? 'ar' : 'en')}
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                >
                  {language === 'en' ? 'العربية' : 'English'}
                </Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className={classes.toolbar}></div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  home: {
    color: 'green'
    //backgroundColor: 'red'
  },
  cartIcon: {
    color: '#92c83e',
    '&:hover': {
      color: 'black'
    }
  },
  nav: {
    backgroundColor: 'white',
    //height: height / 8,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)'
  },
  navMobile: {
    backgroundColor: 'white',
    top: 0,
    height: windowHeight / 6,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)'
  },
  container: {
    backgroundColor: 'white'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: isMobileOnly ? windowWidth / 2.7 : '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#92c83e'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right'),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

export default HomeScreen;
