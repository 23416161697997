import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import history from './history';
import HomeScreen from './screens/HomeScreen';
import CategoriesScreen from './screens/CategoriesScreen';
import SubCategoriesScreen from './screens/SubCategoriesScreen';
import ItemsScreen from './screens/ItemsScreen';
import DetaildItemScreen from './screens/DetailedItemScreen';
import CartScreen from './screens/CartScreen';
import CheckoutScreen from './screens/CheckoutScreen';
import ArticlesScreen from './screens/ArticlesScreen';
import AboutScreen from './screens/AboutScreen';
import ContactScreen from './screens/ContactScreen';
import SearchScreen from './screens/SearchScreen';
import PrivacyScreen from './screens/PrivacyScreen';
import MenuScreen from './screens/MenuScreen';
import ArabicMenuScreen from './screens/ArabicMenuScreen';
import EnglishMenuScreen from './screens/EnglishMenuScreen';
import Layout from './admin/components/Layout';
import Welcome from './admin/Welcome';
import AdminLogin from './admin/AdminLogin';
import AddCategory from './admin/add/AddCategory';
import AddSubCategory from './admin/add/AddSubCategory';
import AddItem from './admin/add/AddItem';
import EditCategory from './admin/edit/EditCategory';
import EditSubCategory from './admin/edit/EditSubCategory';
import EditItem from './admin/edit/EditItem';
import DeleteCategory from './admin/delete/DeleteCategory';
import DeleteSubCategory from './admin/delete/DeleteSubCategory';
import DeleteItem from './admin/delete/DeleteItem';
import MainItems from './admin/MainItems';
import Orders from './admin/Orders';
import AddArticle from './admin/article/AddArticle';
import EditArticle from './admin/article/EditArticle';
import DeleteArticle from './admin/article/DeleteArticle';
import Banner from './admin/Banner';
import { Provider as AuthProvider } from './context/AuthContext';
import { Provider as LanguageProvider } from './context/LanguageContext';
import { Provider as BannerProvider } from './context/BannerContext';
import { Provider as CategoryProvider } from './context/CategoryContext';
import { Provider as ItemProvider } from './context/ItemContext';
import { Provider as CartProvider } from './context/CartContext';
import { Provider as OrderProvider } from './context/OrderContext';
import { Provider as ArticleProvider } from './context/ArticleContext';

const App = () => {
  const THEME = createTheme({
    typography: {
      fontFamily: 'kufam'
    }
  });
  return (
    <div>
      <ThemeProvider theme={THEME}>
        <LanguageProvider>
          <AuthProvider>
            <CategoryProvider>
              <ItemProvider>
                <CartProvider>
                  <BannerProvider>
                    <OrderProvider>
                      <ArticleProvider>
                        <HttpsRedirect>
                          <Router history={history}>
                            <Switch>
                              <Route path="/" exact component={HomeScreen} />
                              <Route
                                path="/menu"
                                exact
                                component={MenuScreen}
                              />
                              <Route
                                path="/arabic-menu"
                                exact
                                component={ArabicMenuScreen}
                              />
                              <Route
                                path="/english-menu"
                                exact
                                component={EnglishMenuScreen}
                              />
                              <Route
                                path="/shop"
                                exact
                                component={CategoriesScreen}
                              />
                              <Route
                                path="/about"
                                exact
                                component={AboutScreen}
                              />
                              <Route
                                path="/contact"
                                exact
                                component={ContactScreen}
                              />
                              <Route
                                path="/search"
                                exact
                                component={SearchScreen}
                              />
                              <Route
                                path="/subcatagory"
                                exact
                                component={SubCategoriesScreen}
                              />
                              <Route
                                path="/items"
                                exact
                                component={ItemsScreen}
                              />
                              <Route
                                path="/detailditem"
                                exact
                                component={DetaildItemScreen}
                              />
                              <Route path="/cart" exact component={CartScreen} />
                              <Route
                                path="/checkout"
                                exact
                                component={CheckoutScreen}
                              />
                              <Route
                                path="/privacy"
                                exact
                                component={PrivacyScreen}
                              />
                              <Route
                                path="/article"
                                exact
                                component={ArticlesScreen}
                              />
                              <Route
                                path="/adminarea"
                                exact
                                component={AdminLogin}
                              />
                              <Layout>
                                <Switch>
                                  <Route
                                    path="/welcomeadmin"
                                    exact
                                    component={Welcome}
                                  />
                                  <Route
                                    path="/add_category"
                                    exact
                                    component={AddCategory}
                                  />
                                  <Route
                                    path="/add_subcategory"
                                    exact
                                    component={AddSubCategory}
                                  />
                                  <Route
                                    path="/add_item"
                                    exact
                                    component={AddItem}
                                  />
                                  <Route
                                    path="/edit_category"
                                    exact
                                    component={EditCategory}
                                  />
                                  <Route
                                    path="/edit_subcategory"
                                    exact
                                    component={EditSubCategory}
                                  />
                                  <Route
                                    path="/edit_item"
                                    exact
                                    component={EditItem}
                                  />
                                  <Route
                                    path="/delete_category"
                                    exact
                                    component={DeleteCategory}
                                  />
                                  <Route
                                    path="/delete_subcategory"
                                    exact
                                    component={DeleteSubCategory}
                                  />
                                  <Route
                                    path="/delete_item"
                                    exact
                                    component={DeleteItem}
                                  />
                                  <Route
                                    path="/orders"
                                    exact
                                    component={Orders}
                                  />
                                  <Route
                                    path="/banner"
                                    exact
                                    component={Banner}
                                  />
                                  <Route
                                    path="/main_items"
                                    exact
                                    component={MainItems}
                                  />
                                  <Route
                                    path="/add_article"
                                    exact
                                    component={AddArticle}
                                  />
                                  <Route
                                    path="/edit_article"
                                    exact
                                    component={EditArticle}
                                  />
                                  <Route
                                    path="/delete_article"
                                    exact
                                    component={DeleteArticle}
                                  />
                                  <Route
                                    path="*"
                                    render={() => <Redirect to="/" />}
                                  />
                                </Switch>
                              </Layout>
                            </Switch>
                          </Router>
                        </HttpsRedirect>
                      </ArticleProvider>
                    </OrderProvider>
                  </BannerProvider>
                </CartProvider>
              </ItemProvider>
            </CategoryProvider>
          </AuthProvider>
        </LanguageProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
