import axios from 'axios';
import createDataContext from './createDataContext';

const authReducer = (state, action) => {
  switch (action.type) {
    case 'error':
      return { ...state, errorMessage: action.payload };
    case 'signin':
      return { ...state, errorMessage: '', token: action.payload };
    case 'signout':
      return { ...state, token: null, errorMessage: '' };
    default:
      return state;
  }
};

async function saveItem(key, value) {
  await window.localStorage.setItem(key, value);
}

async function getItem(key) {
  let result = await window.localStorage.getItem(key);
  return result;
}

async function deleteItem(key) {
  await window.localStorage.removeItem(key);
}

// const signup = dispatch => async (username, password, history) => {
//   try {
//     const response = await axios.post('/api/signup', {
//       username,
//       password
//     });
//     await saveItem('azka_token', response.data.token);
//     dispatch({ type: 'signin', payload: response.data.token });
//     history.push('/add');
//     return { error: '' };
//   } catch (err) {
//     dispatch({ type: 'error', payload: 'Something went wrong with sign up' });
//     return { error: 'Something went wrong with sign up' };
//   }
// };

const signin = dispatch => async (username, password, history) => {
  try {
    const response = await axios.post('/api/signin', {
      username,
      password
    });
    await saveItem('azka_token', response.data.token);
    dispatch({ type: 'signin', payload: response.data.token });
    history.push('/welcomeadmin');
    return { error: '' };
  } catch (err) {
    dispatch({ type: 'error', payload: 'Something went wrong with sign in' });
    return { error: 'Something went wrong with sign in' };
  }
};

const tryLocalSignin = dispatch => async (history, successRoute) => {
  const token = await getItem('azka_token');
  if (token) {
    dispatch({ type: 'signin', payload: token });
    history.push(successRoute);
  } else {
    history.push('/adminarea');
  }
};

const signout = dispatch => async history => {
  await deleteItem('azka_token');
  dispatch({ type: 'signout' });
  history.push('/adminarea');
};

export const { Context, Provider } = createDataContext(
  authReducer,
  { signin, signout, tryLocalSignin }, // signup
  {
    token: window.localStorage.getItem('azka_token')
      ? window.localStorage.getItem('azka_token')
      : null,
    errorMessage: ''
  }
);
