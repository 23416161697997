import React, { useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Drawer,
  makeStyles,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  useMediaQuery,
  Collapse
} from '@material-ui/core';
import LogoImage from '../../images/logo_admin.png';
import { Context as AuthContext } from '../../context/AuthContext';

import {
  Add,
  Edit,
  Delete,
  ExitToApp,
  Menu,
  ExpandMore,
  ExpandLess,
  Inbox,
  PhotoLibrary,
  Description,
  Dns
} from '@material-ui/icons';

const drawerWidth = 240;
const height = window.innerHeight;

const Layout = ({ children }) => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openArticle, setOpenArticle] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);

  const { signout } = useContext(AuthContext);

  const menuItems = [
    {
      title: 'Add',
      icon: <Add className={classes.addIcon} />,
      subs: [
        { subname: 'Add Category', subPath: '/add_category' },
        { subname: 'Add Subcategory', subPath: '/add_subcategory' },
        { subname: 'Add Item', subPath: 'add_item' }
      ]
    },
    {
      title: 'Edit',
      icon: <Edit className={classes.editIcon} />,
      subs: [
        { subname: 'Edit Category', subPath: '/edit_category' },
        { subname: 'Edit Subcategory', subPath: '/edit_subcategory' },
        { subname: 'Edit Item', subPath: 'edit_item' }
      ]
    },
    {
      title: 'Delete',
      icon: <Delete color="error" />,
      subs: [
        { subname: 'Delete Category', subPath: '/delete_category' },
        { subname: 'Delete Subcategory', subPath: '/delete_subcategory' },
        { subname: 'Delete Item', subPath: 'delete_item' }
      ]
    },
    {
      title: 'Banner',
      icon: <PhotoLibrary className={classes.bannerIcon} />,
      path: '/banner'
    },
    {
      title: 'Main Items',
      icon: <Dns className={classes.mainItemsIcon} />,
      path: '/main_items'
    },
    {
      title: 'Orders',
      icon: <Inbox className={classes.orderIcon} />,
      path: '/orders'
    },
    {
      title: 'Articles',
      icon: <Description className={classes.descriptionIcon} />,
      subs: [
        { subname: 'Add Article', subPath: '/add_article' },
        { subname: 'Edit Article', subPath: '/edit_article' },
        { subname: 'Delete Article', subPath: 'delete_article' }
      ]
    },
    {
      title: 'Logout',
      icon: <ExitToApp />
    }
  ];

  return (
    <div className={classes.root}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className={classes.menuButton}
      >
        <Menu />
      </IconButton>
      <Drawer
        variant={isMdUp ? 'permanent' : 'temporary'}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        className={classes.drawer}
        classes={{ paper: classes.drawerTitle }}
        ModalProps={{ keepMounted: true }}
      >
        <Container style={{ display: 'flex' }}>
          <img
            src={LogoImage}
            alt="logo"
            style={{
              objectFit: 'contain',
              width: 600,
              height: 170,
              alignSelf: 'center'
            }}
          />
        </Container>
        <List className={classes.list}>
          {menuItems.map(item => {
            let isOpen;
            if (item.title === 'Add') {
              isOpen = openAdd;
            } else if (item.title === 'Edit') {
              isOpen = openEdit;
            } else if (item.title === 'Delete') {
              isOpen = openDelete;
            } else if (item.title === 'Articles') {
              isOpen = openArticle;
            }
            return (
              <>
                <ListItem
                  button
                  key={item.title}
                  onClick={() => {
                    if (item.title === 'Add') {
                      setOpenAdd(!openAdd);
                    } else if (item.title === 'Edit') {
                      setOpenEdit(!openEdit);
                    } else if (item.title === 'Delete') {
                      setOpenDelete(!openDelete);
                    } else if (item.title === 'Articles') {
                      setOpenArticle(!openArticle);
                    } else if (item.title === 'Logout') {
                      signout(history);
                    } else if (item.path) {
                      handleDrawerToggle();
                      history.push(item.path);
                    }
                  }}
                  className={
                    location.pathname === item.path ? classes.active : null
                  }
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    className={classes.drawerLink}
                    primary={item.title}
                  />
                  {item.subs ? isOpen ? <ExpandLess /> : <ExpandMore /> : null}
                </ListItem>
                {item.subs ? (
                  <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <List
                      component="div"
                      disablePadding
                      className={classes.subList}
                    >
                      {item.subs.map(({ subname, subPath }) => (
                        <ListItem
                          onClick={() => {
                            history.push(subPath);
                            handleDrawerToggle();
                          }}
                          button
                          className={classes.nested}
                        >
                          <ListItemText primary={subname} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                ) : null}
              </>
            );
          })}
        </List>
      </Drawer>
      <div style={{ width: '100%' }}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth
  },
  drawerTitle: {
    width: drawerWidth,
    background: '#f5f5f5'
  },
  root: {
    display: 'flex'
  },
  title: {
    backgroundColor: '#f9f9f9',
    width: '100%'
  },
  drawerLogo: {
    color: 'white',
    marginTop: '5%'
  },
  list: {
    overflowY: 'auto',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    height: '100%',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)'
    }
  },
  active: {
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  addIcon: {
    backgroundColor: 'orange',
    borderRadius: 50,
    color: 'white'
  },
  editIcon: {
    color: '#0277bd'
  },
  menuButton: {
    marginRight: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    height: height / 20
  },
  orderIcon: {
    color: 'green'
  },
  bannerIcon: {
    color: 'purple'
  },
  mainItemsIcon: {
    color: '#92c83e'
  },
  descriptionIcon: {
    color: 'orange'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  subList: {
    backgroundColor: '#e6e6e6'
  }
}));

export default Layout;
