import React, { useContext } from 'react';
import { makeStyles, Typography, Container } from '@material-ui/core';
import NavBar from '../components/NavBar';
import { Context as LanguageContext } from '../context/LanguageContext';
import { isMobileOnly } from 'react-device-detect';
import Footer from '../components/Footer';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const AboutScreen = () => {
  const {
    state: { language }
  } = useContext(LanguageContext);

  const props = {
    language
  };

  const classes = useStyles(props);
  return (
    <div>
      <NavBar />
      <div className={classes.toolbar}></div>
      <Container>
        <Typography className={classes.title}>
          {language === 'en' ? 'About Us' : 'نبذة عنا'}
        </Typography>
        <hr />
        {language === 'en' ? (
          <>
            <Typography className={classes.content}>
              The self is a soul and a body, and the body is a carrier of the
              soul. We are guardians of it, and we will be held accountable for
              it after death
            </Typography>
            <Typography className={classes.content}>
              For the self is a gift created for a purpose. In{' '}
              <span style={{ color: '#92c83e' }}>Azka</span>, we seek to be a
              means of preserving this body that helps the soul to fulfill its
              role.
            </Typography>
            <Typography className={classes.content}>
              Desserts became essential in a lot of people’s lives. Also, when
              materialism invaded the world, the soul and body of Man are being
              filled with waste by altering what Man consumes to become:
            </Typography>
            <Typography className={classes.content}>
              Sugar, hydrogenated oil, refined white flour, flavors, tinctures,
              preservatives, and artificial sweeteners.
            </Typography>
            <Typography className={classes.content}>
              <span style={{ color: '#92c83e' }}>Azka</span> was capable of
              becoming a pioneer of healthy desserts and products in Jordan by
              producing healthy desserts and bakeries for a wide range of
              people, with a strong, knowledgeable, sincere, and honest crew.
            </Typography>
            <Typography className={classes.content}>
              Our desserts are suitable for different people, such as young
              children and the elderly, people on diets, people pursuing a
              healthy lifestyle, breastfeeding and pregnant moms, diabetic
              people, allergic people, and athletes.
            </Typography>
            <Typography className={classes.content}>
              <span style={{ color: '#92c83e' }}>Azka</span>, made with
              integrity
            </Typography>
          </>
        ) : (
          <>
            <Typography className={classes.content}>
              إنَّ النفسَ جسدٌ وروحٌ، وهذا الجسدُ هو دابّةُ تلك الرّوح، وهو
              أمانةٌ سنُسألُ عنها بعد الموت. ولأنّ هذه النّفس نعمة، ولأنّ هذه
              الرّوح لها غاية من خلقها؛ فإنّنا في (أزكى) نسعى أن نكون سببًا في
              حفظ هذا الجسد الذي يعين الرّوح على أداء وظيفتها
            </Typography>
            <Typography className={classes.content}>
              فالحلويات أصبحت جزءًا أساسيًا عند الكثير من الناس. كما وغزت
              العالمَ الماديةُ التي جعلت نفسَ الإنسان وجسدَه مكبًّّاً للنفايات،
              فصار عموم طعام الإنسان: سكرًا، وزيوتًا مهدرجةً، وطحينًا أبيض مكرر،
              ونكهاتٍ وصبغات وموادَ حافظة ومحلّياتٍ كلها صناعيّة، .. إلخ
            </Typography>
            <Typography className={classes.content}>
              استطاع (أزكى) أن يكون رائدًا للحلويّات والمنتجات الصحيّة في
              الأردن، بتقديمه حلويات ومخبوزات صحيّة لشرائح واسعة من الناس، عبر
              طاقم يتميّز بالقوّة والصّدق والأمانة والعلم
            </Typography>
            <Typography className={classes.content}>
              : حلوياتنا تناسب شرائح واسعة من الناس
            </Typography>
            <Typography className={classes.content}>
              كالصغير والكبير، وأصحاب الحميات الغذائية المتنوعة، ومتبّعي أنظمة
              ما يُسمّى بالحياة الصحية، والمرضعات والحوامل، ومرضى السكري، ومن
              يعاني من الحساسيات المتنوعة، والرياضيين
            </Typography>
            <Typography className={classes.content}>
              .أزكى صُنِعَ بصدق
            </Typography>
          </>
        )}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={require('../images/logo.jpeg').default}
            alt="logo"
            className={classes.logo}
          />
        </div>
      </Container>
      <Footer />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  title: {
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right'),
    fontSize: '2em',
    color: '#92c83e'
  },
  content: {
    marginTop: isMobileOnly ? '5%' : '1%',
    fontSize: '1.2em',
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right')
  },
  logo: {
    width: windowWidth / 2,
    height: windowHeight / 4,
    objectFit: 'contain',
    marginTop: '2%',
    marginBottom: '5%'
  }
}));

export default AboutScreen;
