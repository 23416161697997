import React, { useContext } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Container,
  Paper,
  Box
} from '@material-ui/core';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { useHistory } from 'react-router-dom';
import { HighlightOff } from '@material-ui/icons';
import { Context as CartContext } from '../context/CartContext';
import { Context as LanguageContext } from '../context/LanguageContext';
import { isMobileOnly } from 'react-device-detect';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const AWS_URL = 'https://azka-bucket.s3.eu-central-1.amazonaws.com/';

const CartScreen = () => {
  const history = useHistory();

  const {
    removeFromCart,
    state: { cart }
  } = useContext(CartContext);

  const {
    state: { language }
  } = useContext(LanguageContext);

  const props = {
    language
  };

  const classes = useStyles(props);

  return (
    <div>
      <NavBar />
      <div className={classes.toolbar}></div>
      <Container style={{ marginTop: '5%' }}>
        <Typography className={classes.cartText}>
          {language === 'en' ? 'Cart' : 'السلة'}
        </Typography>
        {!cart.length ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <img
              src={require('../images/empty_cart.gif').default}
              style={{
                height: windowHeight / 4,
                objectFit: 'contain'
              }}
            />
            <Typography
              variant="h6"
              style={{ textAlign: 'center', marginBottom: '1%' }}
            >
              {language === 'en' ? 'Cart is empty!' : '!السلة فارغة'}
            </Typography>
            <Button
              variant="contained"
              //color="primary"
              style={{
                backgroundColor: '#92c83e',
                color: 'white',
                marginBottom: '5%',
                width: isMobileOnly ? windowWidth / 2 : windowWidth / 4,
                alignSelf: 'center'
              }}
              onClick={() => history.push('/shop')}
            >
              {language === 'en' ? 'Shop now' : 'تسوق الآن'}
            </Button>
          </div>
        ) : (
          <>
            <Paper elevation={3}>
              {cart.map(item => (
                <div>
                  <Grid
                    container
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Grid xs={3}>
                      <img
                        src={AWS_URL + item.mainImage}
                        alt={item.itemName}
                        className={classes.productImage}
                      />
                    </Grid>
                    <Grid xs={3}>
                      <Typography className={classes.title}>
                        {language === 'en' ? item.itemName : item.arabicName}
                      </Typography>
                    </Grid>
                    <Grid xs={3}>
                      <Typography className={classes.title}>
                        {language === 'en'
                          ? `${item.amount} Piece`
                          : `${item.amount} قطعة`}
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography className={classes.title}>
                        {item.price * item.amount} JD
                      </Typography>
                    </Grid>
                    <Grid xs={1}>
                      <HighlightOff
                        onClick={() => removeFromCart(item.itemName)}
                        color="secondary"
                        className={classes.removeIcon}
                      />
                    </Grid>
                  </Grid>
                  <hr />
                </div>
              ))}
            </Paper>
            <Box
              style={{
                flexDirection: 'row',
                marginTop: '5%',
                marginBottom: '5%'
              }}
            >
              <Button
                color="primary"
                variant="contained"
                className={classes.continueButton}
                onClick={() => history.push('/shop')}
              >
                {language === 'en' ? 'Continue Shopping' : 'متابعة التسوق'}
              </Button>
              <Button
                color="secondary"
                className={classes.checkoutButton}
                variant="contained"
                onClick={() => history.push('/checkout')}
              >
                {language === 'en' ? 'Checkout Now' : 'إتمام عملية الشراء'}
              </Button>
            </Box>
          </>
        )}
      </Container>
      <Footer />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  cartText: {
    color: '#92c83e',
    fontSize: '2.5rem',
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right')
  },
  productImage: {
    objectFit: 'contain',
    height: windowHeight / 5,
    width: windowWidth / 5
  },
  title: {
    color: '#92c83e',
    fontSize: '1.1rem'
  },
  removeIcon: {
    opacity: 0.5,
    '&:hover': {
      cursor: 'pointer',
      opacity: 1,
      transition: 'all 0.3s'
    }
  },
  continueButton: {
    '&:hover': {
      color: 'white'
    }
  },
  checkoutButton: {
    float: 'right',
    '&:hover': {
      color: 'white'
    },
    emptyCart: {
      height: windowHeight / 10,
      //objectFit: 'contain',
      width: windowWidth / 10
    },
    shopButton: {
      width: windowWidth / 2,
      fontSize: ({ language }) => (language === 'en' ? '1.2em' : '1.2em'),
      fontFamily: 'kufam',
      backgroundColor: '#92c83e',
      color: 'white',
      '&:hover': {
        backgroundColor: '#92c83e',
        color: 'white'
      }
    },
    shopButtonMobile: language => ({
      width: windowWidth / 2,
      fontSize: '0.8em',
      backgroundColor: '#92c83e',
      color: 'white',
      '&:hover': {
        backgroundColor: '#92c83e'
      }
    })
  }
}));

export default CartScreen;
