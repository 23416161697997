import React, { useState, useContext, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  TextField,
  Fade,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import { isMobile, isMobileOnly } from 'react-device-detect';
import CustomModal from '../../components/CustomModal';
import { Context as AuthContext } from '../../context/AuthContext';
import { Context as CategoryContext } from '../../context/CategoryContext';

const EditCategory = ({ history }) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [newArabicName, setNewArabicName] = useState('');
  const [newEnglishName, setNewEnglishName] = useState('');
  const [newCategoryImage, setNewCategoryImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [modalText, setModalText] = useState('');
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [resetPhoto, setResetPhoto] = useState(false);

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);
  const {
    fetchCategories,
    editCategory,
    state: { categories }
  } = useContext(CategoryContext);

  useEffect(() => {
    async function fetchCategoriesData() {
      setLoading(true);
      await fetchCategories();
      setLoading(false);
    }

    tryLocalSignin(history, '/edit_category');
    fetchCategoriesData();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    const res = await editCategory(
      selectedCategoryId,
      newArabicName,
      newEnglishName,
      newCategoryImage
    );

    if (res.error) {
      setModalText('There is a problem in editing the category!');
    } else {
      setModalText('Category edited successfully');
      setSelectedCategoryId(null);
      setNewArabicName('');
      setNewEnglishName('');
      setNewCategoryImage(null);
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  };

  const validateImagesSizes = async event => {
    event.preventDefault();

    Object.values(event.target.files).forEach(imgFile => {
      if (imgFile.size > 1048576) {
        setModalText('The image exceeds 1MB limit size');
        setModalVisible(true);
        setShowConfirmButton(false);
        setResetPhoto(true);
        return;
      }
    });

    setNewCategoryImage(event.target.files[0]);
  };

  const classes = useStyles();

  if (!token) {
    return null;
  }

  return (
    <div>
      <Container style={{ marginTop: '1%' }}>
        {loading && (
          <CircularProgress
            style={{
              display: 'flex',
              position: 'absolute',
              alignSelf: 'center',
              top: '50%',
              left: '50%'
            }}
          />
        )}
        <Fade timeout={1000} in={true}>
          <div>
            <Typography className={classes.title}>Edit Category</Typography>
            <Divider />
            <Grid
              className={
                isMobileOnly ? classes.mobileContainer : classes.container
              }
              container
            >
              <Grid md={4} xs={12}>
                <Select
                  placeholder="Choose category"
                  variant="outlined"
                  className={classes.selectCategory}
                  onChange={event => {
                    setSelectedCategoryId(event.target.value._id);
                    setNewArabicName(event.target.value.arabicName);
                    setNewEnglishName(event.target.value.englishName);
                  }}
                >
                  {categories.map(category => (
                    <MenuItem value={category}>{category.englishName}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid md={4} xs={12}>
                <TextField
                  value={newEnglishName}
                  onChange={e => setNewEnglishName(e.target.value)}
                  placeholder="New English Category Name"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid md={4} xs={12}>
                <TextField
                  value={newArabicName}
                  onChange={e => setNewArabicName(e.target.value)}
                  placeholder="New Arabic Category Name"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid direction="row" md={4} xs={12}>
                <Button
                  className={classes.uploadButton}
                  variant="contained"
                  component="label"
                  color="primary"
                >
                  Upload New Photo
                  <input
                    name="photos"
                    type="file"
                    id="files"
                    onChange={event => validateImagesSizes(event)}
                    hidden
                  />
                </Button>
                <Typography variant="h6">
                  {newCategoryImage
                    ? 'New Photo Selected'
                    : 'Please Select New Photo'}
                </Typography>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (!selectedCategoryId || !newEnglishName || !newArabicName) {
                  return;
                }

                setModalText('Do you want to submit this?');
                setShowConfirmButton(true);
                setModalVisible(true);
                setResetPhoto(false);
              }}
            >
              Submit
            </Button>
            <CustomModal
              title="Warning!"
              content={modalText}
              visible={modalVisible}
              onCancel={() => {
                setModalVisible(false);
                if (resetPhoto) setNewCategoryImage(null);
              }}
              onConfirm={handleSubmit}
              showConfirmButton={showConfirmButton}
              loading={loading}
            />
          </div>
        </Fade>
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em'
  },
  container: {
    marginTop: '2%'
  },
  mobileContainer: {
    marginTop: '5%'
  },
  textField: {
    width: isMobileOnly ? '85%' : '60%',
    marginBottom: isMobileOnly ? '5%' : 0
  },
  uploadButton: {
    marginTop: '5%'
  },
  submitButton: {
    float: 'right',
    width: isMobileOnly ? '35%' : '10%',
    marginTop: isMobileOnly ? '5%' : 0,
    marginBottom: '2%'
  },
  selectCategory: {
    width: isMobileOnly ? '85%' : '50%',
    marginBottom: isMobileOnly ? '5%' : 0
  }
});

export default EditCategory;
