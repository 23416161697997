import React, { useEffect, useContext } from 'react';
import {
  makeStyles,
  Typography,
  Box,
  Grid,
  Container
} from '@material-ui/core';
import { Facebook, Instagram } from '@material-ui/icons';
import Aos from 'aos';
import 'aos/dist/aos.css';
import '../css/Footer.css';
import { isMobileOnly } from 'react-device-detect';
import { Context as LanguageContext } from '../context/LanguageContext';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const Footer = () => {
  const {
    state: { language },
  } = useContext(LanguageContext);

  const props = {
    language
  };
  useEffect(() => {
    Aos.init({
      duration: 2000
    });
  }, []);

  const classes = useStyles(props);
  return (
    <section id="contact">
      <Box className={classes.footerContainer}>
        <Container>
          <Grid container>
            <Grid md={4} xs={12}>
              <Typography className={classes.contactTitle}>
                {language === 'en' ? 'Contact Us' : 'تواصل معنا'}
              </Typography>
              <Typography className={classes.contactDetails}>
                {language === 'en' ? 'Mobile: +962 798601716' : 'موبايل : 798601716 962+'}
              </Typography>
              <Typography className={classes.contactDetails}>
                {language === 'en' ? 'E-mail: sales@azkahd.com' : 'إيميل : sales@azkahd.com'}
              </Typography>
            </Grid>
            <Grid md={4} xs={12}>
              <Typography className={classes.contactTitle}>
                {language === 'en' ? 'Follow us' : 'تابعنا على منصات التواصل'}
              </Typography>
              <a
                target="_blank"
                href="https://www.facebook.com/azkahealthydesserts/"
                className="fab fa-facebook"
                rel="noopener noreferrer"
              >
                {' '}
              </a>
              <a
                target="_blank"
                href="https://instagram.com/azkahealthydesserts?utm_medium=copy_link"
                className="fab fa-instagram"
                rel="noopener noreferrer"
              >
                {' '}
              </a>
            </Grid>
            <Grid md={4} xs={12}>
              <Typography className={classes.contactTitle}>
                {language === 'en' ? 'Download App on' : 'حمّل التطبيق'}
              </Typography>
              <Grid container>
                <Grid md={4} xs={12}>
                  <a
                    href='https://apps.apple.com/us/app/azka/id1602493289?platform=iphone'
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require('../images/app_store.png').default}
                      alt="app store"
                      className={classes.appStore}
                    />
                  </a>
                </Grid>
                <Grid md={4} xs={12}>
                  <a
                    href='https://play.google.com/store/apps/details?id=com.mateco.Azka'
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require('../images/google_play.png').default}
                      alt="app store"
                      className={classes.googleStore}
                    />
                  </a>
                </Grid>
                <Grid md={4} xs={12}>
                  <img
                    src={require('../images/app_gallery.png').default}
                    alt="app store"
                    className={classes.appGalleryStore}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid md={4} xs={12}>
              <Typography className={classes.poweredText}>
                Powered by{' '}
                <a
                  target="_blank"
                  href="https://www.mate-co.com"
                  rel="noopener noreferrer"
                  className={classes.mateLink}
                >
                  Mate-co
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </section>
  );
};

const useStyles = makeStyles({
  facebookIcon: {
    fontSize: '2.5em',
    color: '#3b5998'
  },
  instagramIcon: {
    fontSize: '2.5em',
    color: '#fb3958'
  },
  footerContainer: {
    backgroundColor: '#92c83e',
    height: isMobileOnly ? '' : windowHeight / 4,
    direction: ({ language }) => (language === 'en' ? 'ltr' : 'rtl')
  },
  contactTitle: {
    color: 'white',
    fontSize: '1.5em',
    marginTop: '2%'
  },
  contactDetails: {
    color: 'white',
    fontSize: '1em',
    marginTop: '2%'
  },
  poweredText: {
    color: 'white',
    fontSize: '1em',
    marginTop: '6%'
  },
  mateLink: {
    color: 'white'
  },
  appStore: {
    width: isMobileOnly ? '50%' : '90%',
    objectFit: 'contain'
  },
  googleStore: {
    width: isMobileOnly ? '50%' : '90%',
    objectFit: 'contain',
    marginTop: isMobileOnly ? '2%' : 0
  },
  appGalleryStore: {
    width: isMobileOnly ? '50%' : '90%',
    objectFit: 'contain',
    marginTop: isMobileOnly ? '2%' : 0,
    marginBottom: isMobileOnly ? '2%' : 0
  }
});

export default Footer;
