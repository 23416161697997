import React from "react";
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import { isMobile } from "react-device-detect";

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const MenuScreen = () => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <Box className={classes.container}>
      <img
        src={require('../images/white_logo.jpeg').default}
        alt="menu"
        className={classes.logo}
      />
      <Typography className={classes.title}>Menu - القائمة</Typography>
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => history.push('/arabic-menu')}
      >
        عربي
      </Button>
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => history.push('/english-menu')}
      >
        English
      </Button>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    backgroundColor: '#92c83e', //#92c83e
    height: windowHeight,
    width: windowWidth,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    objectFit: 'contain',
    width: isMobile ? '100%' : '30%'
  },
  title: {
    color: '#FFFFFF',
    fontSize: '1.8em'
  },
  button: {
    backgroundColor: '#535c49',
    width: isMobile ? '70%' : '40%',
    color: '#FFFFFF',
    fontSize: '1.5em',
    marginTop: isMobile ? '5%' : '2%',
    '&:hover': {
      backgroundColor: '#535c49',
      color: 'white'
    },
  }
})

export default MenuScreen;