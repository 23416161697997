import axios from 'axios';
import createDataContext from './createDataContext';
import { uploadNewImages, delete_images } from './commons';

const articleReducer = (state, action) => {
  switch (action.type) {
    case 'error':
      return { ...state, errorMessage: action.payload };
    case 'upload_article':
      return { ...state, errorMessage: '' };
    case 'edit_article':
      return { ...state, errorMessage: '' };
    case 'delete_article':
      return { ...state, errorMessage: '' };
    case 'fetch_article':
      return { ...state, errorMessage: '', articles: action.payload };
    default:
      return state;
  }
};

const uploadArticle = dispatch => async (articleData, articleImage) => {
  try {
    const upload_image_res = await uploadNewImages([articleImage], 'Articles');
    if (upload_image_res.error) {
      dispatch({
        type: 'error',
        payload: upload_image_res.error
      });
      return { error: upload_image_res.error };
    }

    const res = await axios.post('/api/upload/article', {
      image: upload_image_res.imgsConfigs.map(config => config.data.key)[0],
      ...articleData
    });
    dispatch({ type: 'upload_article', payload: res.data });
    return { error: '' };
  } catch (err) {
    console.log(err);
    dispatch({
      type: 'error',
      payload: 'Something went wrong while uploading the article'
    });
    return { error: 'Error in Uploading the article' };
  }
};

const fetchArticles = dispatch => async () => {
  try {
    const res = await axios.get('/api/data/articles');
    dispatch({ type: 'fetch_article', payload: res.data });
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while fetching the articles'
    });
    return { error: 'Error in Fetching the articles' };
  }
};

const editArticle =
  dispatch => async (articleId, editedFields, articleImage) => {
    try {
      await axios.post('/api/edit/article', { articleId, editedFields });

      dispatch({ type: 'edit_article' });
    } catch (err) {
      dispatch({
        type: 'error',
        payload: 'Something went wrong while editing the article'
      });
      return { error: 'Error in editing the article' };
    }

    if (articleImage) {
      const upload_image_res = await uploadNewImages(
        [articleImage],
        'Articles'
      );
      if (upload_image_res.error) {
        dispatch({
          type: 'error',
          payload: upload_image_res.error
        });
        return { error: upload_image_res.error };
      }

      try {
        const edit_image_res = await axios.post('/api/edit/article', {
          articleId,
          editedFields: {
            image: upload_image_res.imgsConfigs.map(
              config => config.data.key
            )[0]
          }
        });
        const delete_res = await delete_images([
          edit_image_res.data.article.image
        ]);
        if (delete_res.error) {
          dispatch({
            type: 'error',
            payload: delete_res.error
          });
          return { error: delete_res.error };
        }
      } catch (err) {
        dispatch({
          type: 'error',
          payload: 'Something went wrong while editing the article'
        });
        return { error: 'Error in Editing the article' };
      }
    }

    return { error: '' };
  };

const deleteArticle = dispatch => async articleId => {
  try {
    const res = await axios.post('/api/delete/article', { articleId });

    delete_images([res.data.article.image]);

    dispatch({ type: 'delete_article' });
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while deleting the article'
    });
    return { error: 'Error in deleting the article' };
  }
};

export const { Context, Provider } = createDataContext(
  articleReducer,
  { fetchArticles, uploadArticle, editArticle, deleteArticle },
  { articles: [], errorMessage: '' }
);
