import axios from 'axios';
import createDataContext from './createDataContext';

const orderReducer = (state, action) => {
  switch (action.type) {
    case 'upload_order':
      return { ...state, errorMessage: '' };
    case 'fetch_order':
      return { ...state, orders: action.payload };
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const fetchOrders = dispatch => async () => {
  try {
    const res = await axios.get('/api/data/order');
    dispatch({ type: 'fetch_order', payload: res.data });
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while fetching the order'
    });
    return { error: 'fetching Orders failed!' };
  }
};

const uploadOrder = dispatch => async orderDetails => {
  try {
    await axios.post('/api/upload/order', orderDetails);
    dispatch({ type: 'upload_order' });
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while uploading the order'
    });
    return { error: 'upload Order failed!' };
  }
};

export const { Context, Provider } = createDataContext(
  orderReducer,
  {
    uploadOrder,
    fetchOrders
  },
  { orders: [], errorMessage: '' }
);
