import React from 'react';

const height = window.innerHeight;
const width = window.innerWidth;

const PrivacyScreen = () => (
    <section id="privacy">
        <div className="container">
            <nav
                className="navbar navbar-expand-lg navbar-light" //rgba(0,0,0,0.8)
            >
                <div className="container">
                    <a className="navbar-brand" href="/privacy">
                        <img
                            src={require('../images/logo.jpeg').default}
                            alt="logo"
                            className="logo"
                            style={{
                                objectFit: 'contain',
                                width: width / 10
                            }}
                        />
                    </a>
                </div>
            </nav>
            <hr></hr>
            <h2>Privacy Policy</h2>
            <p>
                For the self is a gift created for a purpose. In <strong>Azka</strong>, we seek to be a means of preserving this body that helps the soul to fulfill its role.
            </p>
            <p>
                This page is used to inform visitors regarding our policies with the
                collection, use, and disclosure of personal information if anyone
                decided to use our service.
            </p>
            <p>
                If you choose to use our service, then you agree to the collection and
                use of information in relation with this policy. The Personal
                Information that we collect are used for providing and improving the
                service. We will not use or share your information with anyone except as
                described in this privacy policy.
            </p>
            <p>
                <h4>Information Collection and Use</h4>
            </p>
            <p>
                For a better experience while using our service, we may require you to
                provide us with certain personally identifiable information, including
                but not limited to users name, phone number and the address. The
                information that we request is retained on your device and is not
                collected by us in any way.
            </p>

            <p>
                <strong>Service Providers</strong> we may employ third-party companies
                and individuals due to the following reasons:
            </p>
            <ul>
                <li>To facilitate our service.</li>
                <li>To assist us in analyzing how our service is used.</li>
            </ul>
            <p>
                We want to inform users of this service that these third parties have
                access to your personal information. The reason is to perform the tasks
                assigned to them on our behalf. However, they are obligated not to
                disclose or use the information for any other purpose.
            </p>
            <p>
                <h4>Children’s Privacy</h4>
            </p>
            <p>
                This services do not address anyone under the age of 13. We do not
                knowingly collect personal identifiable information from children under
                13. If you are a parent or guardian and you are aware that your child
                has provided us with personal information, please contact us so that we
                will be able to do necessary actions.
            </p>
            <p>
                <h4>Changes to This Privacy Policy</h4>
            </p>
            <p>
                We may update our Privacy Policy from time to time. Thus, you are
                advised to review this page periodically for any changes. We will notify
                you of any changes by posting the new Privacy Policy on this page. These
                changes are effective immediately, after they are posted on this page.
            </p>
            <p>
                <h4>Contact Us</h4>
            </p>
            <p>
                If you have any questions or suggestions about our Privacy Policy, do
                not hesitate to contact us via email: sales@azkahd.com
            </p>
        </div>
    </section>
);

export default PrivacyScreen;