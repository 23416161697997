import React, { useContext } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  Fade,
  TextField
} from '@material-ui/core';
import NavBar from '../components/NavBar';
import { useLocation } from 'react-router-dom';
import { Context as LanguageContext } from '../context/LanguageContext';
import { isMobileOnly } from 'react-device-detect';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;
const AWS_URL = 'https://azka-bucket.s3.eu-central-1.amazonaws.com/';

const Articles = () => {
  const {
    state: { language }
  } = useContext(LanguageContext);

  const props = {
    language
  };
  const location = useLocation();
  const image = location.state.image;
  const arabicTitle = location.state.arabicTitle;
  const englishTitle = location.state.englishTitle;
  const arabicBody = location.state.arabicBody;
  const englishBody = location.state.englishBody;

  const classes = useStyles(props);
  return (
    <div>
      <NavBar />
      <div className={classes.toolbar}></div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src={AWS_URL + image}
          alt="main-image"
          className={classes.mainImage}
        />
      </div>
      <Container>
        <Typography className={classes.title}>
          {language === 'en' ? englishTitle : arabicTitle}
        </Typography>
        <hr />
        <Typography variant="body1" className={classes.articleText}>
          {language === 'en' ? englishBody : arabicBody}
        </Typography>
      </Container>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  //toolbar: theme.mixins.toolbar,
  mainImage: {
    //width: windowWidth,
    height: isMobileOnly ? windowHeight / 4 : windowHeight / 1.8,
    marginTop: '2%'
  },
  title: {
    textAlign: 'center',
    fontSize: '2em',
    marginTop: '2%',
    color: '#92c83e'
  },
  articleText: {
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right'),
    whiteSpace: 'pre-line',
    wordWrap: 'break-word'
  }
}));

export default Articles;
