import React, { useContext, useEffect } from 'react';
import WelcomeImage from '../images/welcome.gif';
import { Context as AuthContext } from '../context/AuthContext';

const windowWidth = window.innerWidth;

const Welcome = ({ history }) => {
  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);

  useEffect(() => {
    tryLocalSignin(history, '/welcomeadmin');
  }, []);

  if (!token) {
    return null;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img
        src={WelcomeImage}
        alt="welcome"
        style={{ objectFit: 'contain', width: windowWidth / 2.2 }}
      />
    </div>
  );
};

export default Welcome;
