import React, { useState, useContext, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Fade,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import CustomModal from '../../components/CustomModal';
import { Context as AuthContext } from '../../context/AuthContext';
import { Context as CategoryContext } from '../../context/CategoryContext';
import { Context as ItemContext } from '../../context/ItemContext';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const EditItem = ({ history }) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [selectedSubCategoryName, setSelectedSubCategoryName] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [newArabicName, setNewArabicName] = useState('');
  const [newEnglishName, setNewEnglishName] = useState('');
  const [price, setPrice] = useState('');
  const [keto, setKeto] = useState(false);
  const [diabetes, setDiabetes] = useState(false);
  const [gluten, setGluten] = useState(false);
  const [handMade, setHandMade] = useState(false);
  const [lactose, setLactose] = useState(false);
  const [lowCarbs, setLowCarbs] = useState(false);
  const [natural, setNatural] = useState(false);
  const [sugar, setSugar] = useState(false);
  const [englishDescription, setEnglishDescription] = useState('');
  const [arabicDescription, setArabicDescription] = useState('');
  const [englishIngredients, setEnglishIngredients] = useState('');
  const [arabicIngredients, setArabicIngredients] = useState('');
  const [englishNutritionFacts, setEnglishNutritionFacts] = useState('');
  const [arabicNutritionFacts, setArabicNutritionFacts] = useState('');
  const [englishStorage, setEnglishStorage] = useState('');
  const [arabicStorage, setArabicStorage] = useState('');
  const [itemMainImage, setItemMainImage] = useState(null);
  const [itemImages, setItemImages] = useState(null);
  const [nutritionFactsImages, setNutritionFactsImages] = useState(null);

  const [loading, setLoading] = useState(false);
  const [modalText, setModalText] = useState('');
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [resetPhotos, setResetPhotos] = useState(false);

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);
  const {
    fetchCategories,
    fetchSubCategories,
    state: { categories, subcategories }
  } = useContext(CategoryContext);

  const {
    fetchItems,
    resetItemsState,
    editItem,
    state: { items }
  } = useContext(ItemContext);

  const classes = useStyles();

  useEffect(() => {
    async function fetchCategoriesData() {
      setLoading(true);
      await fetchCategories();
      setLoading(false);
    }

    tryLocalSignin(history, '/edit_item');
    fetchCategoriesData();
  }, []);

  const resetStates = () => {
    setSelectedSubCategoryId(null);

    setSelectedItemId(null);
    setNewArabicName('');
    setNewEnglishName('');
    setPrice('');
    setKeto(false);
    setDiabetes(false);
    setGluten(false);
    setHandMade(false);
    setLactose(false);
    setLowCarbs(false);
    setNatural(false);
    setSugar(false);
    setEnglishDescription('');
    setArabicDescription('');
    setEnglishIngredients('');
    setArabicIngredients('');
    setEnglishNutritionFacts('');
    setArabicNutritionFacts('');
    setEnglishStorage('');
    setArabicStorage('');

    setItemMainImage(null);
    setItemImages(null);
    setNutritionFactsImages(null);
  };

  const validateImagesSizes = event => {
    event.preventDefault();

    try {
      Object.values(event.target.files).forEach(imgFile => {
        if (imgFile.size > 1048576) {
          setModalText('There are images that exceed 1MB limit size');
          setModalVisible(true);
          setShowConfirmButton(false);
          //setResetPhotos(true);
          throw 'Exceed limit error';
        }
      });
      return true;
    } catch {
      return false;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    const props = [];
    if (keto) {
      props.push('keto');
    }
    if (diabetes) {
      props.push('diabetes');
    }
    if (gluten) {
      props.push('gluten');
    }
    if (handMade) {
      props.push('hand_made');
    }
    if (lactose) {
      props.push('lactose');
    }
    if (lowCarbs) {
      props.push('low_carbs');
    }
    if (natural) {
      props.push('natural');
    }
    if (sugar) {
      props.push('sugar');
    }

    const itemDetails = {
      englishName: newEnglishName,
      arabicName: newArabicName,
      price,
      props,
      englishDescription,
      arabicDescription,
      englishIngredients,
      arabicIngredients,
      englishStorage,
      arabicStorage,
      englishNutritionFacts,
      arabicNutritionFacts
    };

    const itemDetailsImages = {
      mainImage: itemMainImage,
      images: itemImages,
      nutritionFactsImages
    };

    const res = await editItem(
      itemDetails,
      itemDetailsImages,
      selectedItemId,
      selectedSubCategoryName
    );

    if (res.error) {
      setModalText('There is a problem in editing the item!');
    } else {
      setModalText('Item edited successfully');
      await fetchItems(selectedSubCategoryId);
      await fetchSubCategories(selectedCategoryId);
      await fetchCategories();
      resetStates();
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  };

  if (!token) {
    return null;
  }

  return (
    <div>
      <Container style={{ marginTop: '1%' }}>
        {loading && (
          <CircularProgress
            style={{
              display: 'flex',
              position: 'absolute',
              alignSelf: 'center',
              top: '50%',
              left: '50%'
            }}
          />
        )}
        <Fade timeout={1000} in={true}>
          <div>
            <Typography className={classes.title}>Edit Item</Typography>
            <Divider />
            <Grid className={classes.container} container>
              <Grid md={4} xs={12}>
                <Typography className={classes.chooseText}>
                  Choose Category
                </Typography>
                <Select
                  placeholder="Choose category"
                  variant="outlined"
                  className={classes.selectCategory}
                  onChange={async event => {
                    setSelectedSubCategoryId(null);
                    setLoading(true);
                    setSelectedCategoryId(event.target.value);
                    await fetchSubCategories(event.target.value);
                    resetStates();
                    resetItemsState();
                    setLoading(false);
                  }}
                >
                  {categories.map(category => (
                    <MenuItem value={category._id}>
                      {category.englishName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid md={4} xs={12}>
                <Typography className={classes.chooseText}>
                  Choose Subcategory
                </Typography>
                <Select
                  placeholder="Choose Subcategory"
                  variant="outlined"
                  className={classes.selectCategory}
                  onChange={async event => {
                    resetStates();
                    setSelectedSubCategoryId(event.target.value._id);
                    setSelectedSubCategoryName(event.target.value.englishName);
                    setLoading(true);
                    await fetchItems(event.target.value._id);
                    setLoading(false);
                  }}
                >
                  {subcategories.map(subcategory => (
                    <MenuItem value={subcategory}>
                      {subcategory.englishName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid md={4} xs={12}>
                <Typography className={classes.chooseText}>
                  Choose Item
                </Typography>
                <Select
                  placeholder="Choose category"
                  variant="outlined"
                  className={classes.selectCategory}
                  onChange={event => {
                    const props = event.target.value.props;

                    setSelectedItemId(event.target.value._id);
                    setNewArabicName(event.target.value.arabicName);
                    setNewEnglishName(event.target.value.englishName);
                    setPrice(event.target.value.price);
                    setEnglishDescription(
                      event.target.value.englishDescription
                    );
                    setArabicDescription(event.target.value.arabicDescription);
                    setEnglishIngredients(
                      event.target.value.englishIngredients
                    );
                    setArabicIngredients(event.target.value.arabicIngredients);
                    setEnglishStorage(event.target.value.englishStorage);
                    setArabicStorage(event.target.value.arabicStorage);
                    setEnglishNutritionFacts(
                      event.target.value.englishNutritionFacts
                    );
                    setArabicNutritionFacts(
                      event.target.value.arabicNutritionFacts
                    );

                    if (props.includes('keto')) {
                      setKeto(true);
                    } else {
                      setKeto(false);
                    }
                    if (props.includes('diabetes')) {
                      setDiabetes(true);
                    } else {
                      setDiabetes(false);
                    }
                    if (props.includes('gluten')) {
                      setGluten(true);
                    } else {
                      setGluten(false);
                    }
                    if (props.includes('handMade')) {
                      setHandMade(true);
                    } else {
                      setHandMade(false);
                    }
                    if (props.includes('lactose')) {
                      setLactose(true);
                    } else {
                      setLactose(false);
                    }
                    if (props.includes('lowCarbs')) {
                      setLowCarbs(true);
                    } else {
                      setLowCarbs(false);
                    }
                    if (props.includes('natural')) {
                      setNatural(true);
                    } else {
                      setNatural(false);
                    }
                    if (props.includes('sugar')) {
                      setSugar(true);
                    } else {
                      setSugar(false);
                    }
                  }}
                >
                  {items.map(item => (
                    <MenuItem value={item}>{item.englishName}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid md={4} xs={12}>
                <TextField
                  value={newEnglishName}
                  onChange={e => setNewEnglishName(e.target.value)}
                  placeholder="New English Item Name"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid md={4} xs={12}>
                <TextField
                  value={newArabicName}
                  onChange={e => setNewArabicName(e.target.value)}
                  placeholder="New Arabic Item Name"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid md={4} xs={12}>
                <TextField
                  value={price}
                  onChange={e => setPrice(e.target.value)}
                  placeholder="New Price"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid direction="row" md={4} xs={12}>
                <Button
                  className={classes.uploadButton}
                  variant="contained"
                  component="label"
                  color="primary"
                >
                  Upload Item Main Photo
                  <input
                    name="photos"
                    type="file"
                    id="files"
                    onChange={event => {
                      const passValidation = validateImagesSizes(event);
                      if (passValidation) {
                        setItemMainImage(event.target.files[0]);
                      }
                    }}
                    hidden
                  />
                </Button>
                <Typography className={classes.uploadText}>
                  {itemMainImage
                    ? 'Main Photo Selected'
                    : 'Please Upload Item Main Photo'}
                </Typography>
              </Grid>
              <Grid direction="row" md={4} xs={12}>
                <Button
                  className={classes.uploadButton}
                  variant="contained"
                  component="label"
                  color="primary"
                >
                  Upload Item Photos
                  <input
                    name="photos"
                    type="file"
                    id="files"
                    onChange={event => {
                      const passValidation = validateImagesSizes(event);
                      if (passValidation) {
                        setItemImages(event.target.files);
                      }
                    }}
                    hidden
                    multiple
                  />
                </Button>
                <Typography className={classes.uploadText}>
                  {itemImages
                    ? `${itemImages.length} Image(s) selected`
                    : 'Please Upload Item Photos'}
                </Typography>
              </Grid>

              <Grid direction="row" xs={12}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <Typography className={classes.selectText}>
                    Select Categories
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={keto}
                          onChange={e => setKeto(e.target.checked)}
                          name="keto"
                        />
                      }
                      label="Keto"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={diabetes}
                          onChange={e => setDiabetes(e.target.checked)}
                          name="diabetes"
                        />
                      }
                      label="Diabetes"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={gluten}
                          onChange={e => setGluten(e.target.checked)}
                          name="gluten"
                        />
                      }
                      label="Gluten"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={handMade}
                          onChange={e => setHandMade(e.target.checked)}
                          name="gluten"
                        />
                      }
                      label="Hand Made"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={lactose}
                          onChange={e => setLactose(e.target.checked)}
                          name="gluten"
                        />
                      }
                      label="Lactose"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={lowCarbs}
                          onChange={e => setLowCarbs(e.target.checked)}
                          name="gluten"
                        />
                      }
                      label="Low Carbs"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={natural}
                          onChange={e => setNatural(e.target.checked)}
                          name="gluten"
                        />
                      }
                      label="Natural"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={sugar}
                          onChange={e => setSugar(e.target.checked)}
                          name="gluten"
                        />
                      }
                      label="Sugar"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid md={6} xs={12}>
                <TextField
                  value={englishDescription}
                  onChange={e => setEnglishDescription(e.target.value)}
                  className={classes.textField}
                  placeholder="New English Description"
                  variant="outlined"
                  multiline
                  inputProps={{
                    style: {
                      height: windowHeight / 8,
                      width: isMobileOnly ? windowWidth / 2 : windowWidth / 6
                    }
                  }}
                />
              </Grid>
              <Grid md={6} xs={12}>
                <TextField
                  value={arabicDescription}
                  onChange={e => setArabicDescription(e.target.value)}
                  className={classes.textField}
                  placeholder="New Arabic Description"
                  variant="outlined"
                  multiline
                  inputProps={{
                    style: {
                      height: windowHeight / 8,
                      width: isMobileOnly ? windowWidth / 2 : windowWidth / 6
                    }
                  }}
                />
              </Grid>
              <Grid md={6} xs={12}>
                <TextField
                  value={englishIngredients}
                  onChange={e => setEnglishIngredients(e.target.value)}
                  className={classes.textField}
                  placeholder="New English Ingredients"
                  variant="outlined"
                  multiline
                  inputProps={{
                    style: {
                      height: windowHeight / 8,
                      width: isMobileOnly ? windowWidth / 2 : windowWidth / 6
                    }
                  }}
                />
              </Grid>
              <Grid md={6} xs={12}>
                <TextField
                  value={arabicIngredients}
                  onChange={e => setArabicIngredients(e.target.value)}
                  className={classes.textField}
                  placeholder="New Arabic Ingredients"
                  variant="outlined"
                  multiline
                  inputProps={{
                    style: {
                      height: windowHeight / 8,
                      width: isMobileOnly ? windowWidth / 2 : windowWidth / 6
                    }
                  }}
                />
              </Grid>
              <Grid md={6} xs={12}>
                <TextField
                  value={englishStorage}
                  onChange={e => setEnglishStorage(e.target.value)}
                  className={classes.textField}
                  placeholder="New English Storage"
                  variant="outlined"
                  multiline
                  inputProps={{
                    style: {
                      height: windowHeight / 8,
                      width: isMobileOnly ? windowWidth / 2 : windowWidth / 6
                    }
                  }}
                />
              </Grid>
              <Grid md={6} xs={12}>
                <TextField
                  value={arabicStorage}
                  onChange={e => setArabicStorage(e.target.value)}
                  className={classes.textField}
                  placeholder="New Arabic Storage"
                  variant="outlined"
                  multiline
                  inputProps={{
                    style: {
                      height: windowHeight / 8,
                      width: isMobileOnly ? windowWidth / 2 : windowWidth / 6
                    }
                  }}
                />
              </Grid>
              <Grid md={6} xs={12}>
                <TextField
                  value={englishNutritionFacts}
                  onChange={e => setEnglishNutritionFacts(e.target.value)}
                  className={classes.textField}
                  placeholder="New English Nutrition Facts"
                  variant="outlined"
                  multiline
                  inputProps={{
                    style: {
                      height: windowHeight / 8,
                      width: isMobileOnly ? windowWidth / 2 : windowWidth / 6
                    }
                  }}
                />
              </Grid>
              <Grid md={6} xs={12}>
                <TextField
                  value={arabicNutritionFacts}
                  onChange={e => setArabicNutritionFacts(e.target.value)}
                  className={classes.textField}
                  placeholder="New Arabic Nutrition Facts"
                  variant="outlined"
                  multiline
                  inputProps={{
                    style: {
                      height: windowHeight / 8,
                      width: isMobileOnly ? windowWidth / 2 : windowWidth / 6
                    }
                  }}
                />
              </Grid>
              <Grid direction="row" xs={12}>
                <Button
                  className={classes.uploadNutrition}
                  variant="contained"
                  component="label"
                  color="primary"
                >
                  Upload Nutrition Facts Photos
                  <input
                    name="photos"
                    type="file"
                    id="files"
                    onChange={event => {
                      const passValidation = validateImagesSizes(event);
                      if (passValidation) {
                        setNutritionFactsImages(event.target.files);
                      }
                    }}
                    hidden
                    multiple
                  />
                </Button>
                <Typography className={classes.uploadText}>
                  {nutritionFactsImages
                    ? `${nutritionFactsImages.length} Image(s) selected`
                    : 'Please Upload Nutrition Facts Photos'}
                </Typography>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (
                  !selectedSubCategoryId ||
                  !selectedItemId ||
                  !newArabicName ||
                  !newEnglishName ||
                  !price
                ) {
                  return;
                }

                setModalText('Do you want to submit this?');
                setShowConfirmButton(true);
                setModalVisible(true);
                setResetPhotos(false);
              }}
            >
              Submit
            </Button>
            <CustomModal
              title="Warning!"
              content={modalText}
              visible={modalVisible}
              onCancel={() => {
                setModalVisible(false);
                if (resetPhotos) {
                  setItemMainImage(null);
                  setItemImages(null);
                  setNutritionFactsImages(null);
                }
              }}
              onConfirm={handleSubmit}
              showConfirmButton={showConfirmButton}
              loading={loading}
            />
          </div>
        </Fade>
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em'
  },
  container: {
    marginTop: isMobileOnly ? '5%' : '2%'
  },
  uploadButton: {
    marginTop: '5%'
  },
  uploadText: {
    fontSize: '1em',
    marginTop: '1%'
  },
  selectText: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    marginTop: '5%'
  },
  textField: {
    marginTop: '5%'
  },
  submitButton: {
    float: 'right',
    width: isMobileOnly ? '35%' : '10%',
    marginTop: isMobileOnly ? '5%' : 0,
    marginBottom: '2%'
  },
  chooseText: {
    fontSize: '1.3em',
    marginBottom: '1%'
  },
  selectCategory: {
    width: isMobileOnly ? '85%' : '60%',
    marginBottom: '5%'
  },
  uploadNutrition: {
    marginTop: isMobileOnly ? '5%' : '3%'
  }
});

export default EditItem;
