import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Container,
  Divider,
  TextField,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import NavBar from '../components/NavBar';
import CustomModal from '../components/CustomModal';
import { Context as OrderContext } from '../context/OrderContext';
import { Context as CartContext } from '../context/CartContext';
import { Context as LanguageContext } from '../context/LanguageContext';
import { isMobileOnly } from 'react-device-detect';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const governorates = [
  { arabicName: 'عمّان', englishName: 'Amman', deliveryPrice: 0 },
  { arabicName: 'الزرقاء', englishName: 'Zarqa', deliveryPrice: 4.5 },
  { arabicName: 'السلط', englishName: 'Salt', deliveryPrice: 4.5 },
  { arabicName: 'مادبا', englishName: 'Madaba', deliveryPrice: 4.5 },
  { arabicName: 'سحاب', englishName: 'Sahab', deliveryPrice: 4.5 },
  { arabicName: 'إربد', englishName: 'Irbid', deliveryPrice: 6.5 },
  { arabicName: 'البلقاء', englishName: 'Balqa', deliveryPrice: 6.5 },
  { arabicName: 'جرش', englishName: 'Jerash', deliveryPrice: 6.5 },
  { arabicName: 'الطفيلة', englishName: 'Tafilah', deliveryPrice: 6.5 },
  { arabicName: 'عجلون', englishName: 'Ajloun', deliveryPrice: 6.5 },
  { arabicName: 'الكرك', englishName: 'Karak', deliveryPrice: 6.5 },
  { arabicName: 'معان', englishName: "Ma'an", deliveryPrice: 6.5 },
  { arabicName: 'المفرق', englishName: 'Mafraq', deliveryPrice: 6.5 },
  { arabicName: 'العقبة', englishName: 'Aqaba', deliveryPrice: 7 }
];

const ammanRegions = [
  {
    arabicName: 'عبدالله غوشة',
    englishName: 'Abdullah Ghosheh',
    deliveryPrice: 1.5
  },
  {
    arabicName: 'الروابي',
    englishName: 'AlRawabi',
    deliveryPrice: 1.5
  },
  { arabicName: 'السابع', englishName: 'The Seventh Circle', deliveryPrice: 2 },
  {
    arabicName: 'شارع مكة',
    englishName: 'Mecca Street',
    deliveryPrice: 2
  },
  {
    arabicName: 'مجمع الحسين للأعمال',
    englishName: 'Business Park',
    deliveryPrice: 2
  },
  {
    arabicName: 'ام أذينة',
    englishName: 'Um Uthainah',
    deliveryPrice: 2.5
  },
  {
    arabicName: 'دير غبار',
    englishName: 'Deer Ghbar',
    deliveryPrice: 2.5
  },
  { arabicName: 'عبدون', englishName: 'Abdoun', deliveryPrice: 2.5 },
  {
    arabicName: 'ام السماق',
    englishName: 'Um AlSommaq',
    deliveryPrice: 2.5
  },
  {
    arabicName: 'تلاع العلي',
    englishName: "Tla' Al-ali",
    deliveryPrice: 2.5
  },
  {
    arabicName: 'الشميساني',
    englishName: 'Shmeesani',
    deliveryPrice: 2.5
  },
  {
    arabicName: 'الرابية',
    englishName: 'Al-Rabiah',
    deliveryPrice: 2.5
  },
  {
    arabicName: 'الجندويل',
    englishName: 'Jandaweel',
    deliveryPrice: 2.5
  },
  {
    arabicName: 'الكرسي',
    englishName: 'Al-Kursi',
    deliveryPrice: 2.5
  },
  {
    arabicName: 'الثامن',
    englishName: 'The Eighth Circle',
    deliveryPrice: 2.5
  },
  {
    arabicName: 'الجاردنز',
    englishName: 'Gardens',
    deliveryPrice: 2.5
  },
  {
    arabicName: 'العبدلي',
    englishName: 'Abdali',
    deliveryPrice: 3
  },
  {
    arabicName: 'وادي صقرة',
    englishName: 'Wadi Saqrah',
    deliveryPrice: 3
  },
  {
    arabicName: 'اللويبدة',
    englishName: 'Al-Weibdeh',
    deliveryPrice: 3
  },
  {
    arabicName: 'جبل عمّان',
    englishName: 'Jabal Amman',
    deliveryPrice: 3
  },
  {
    arabicName: 'جبل الحسين',
    englishName: 'Jabal Al-Huusain',
    deliveryPrice: 3
  },
  {
    arabicName: 'خلدا',
    englishName: 'Khaldah',
    deliveryPrice: 3
  },
  {
    arabicName: 'دابوق',
    englishName: 'Dabouq',
    deliveryPrice: 3.5
  },
  {
    arabicName: 'صويلح',
    englishName: 'Sweileh',
    deliveryPrice: 3.5
  },
  {
    arabicName: 'الياسمين',
    englishName: 'Al-Yasmeen',
    deliveryPrice: 3
  },
  {
    arabicName: 'المدينة الرياضية',
    englishName: 'Sport City',
    deliveryPrice: 3
  },
  {
    arabicName: 'عرجان',
    englishName: 'Arjan',
    deliveryPrice: 3
  },
  {
    arabicName: 'ضاحية الرشيد',
    englishName: 'Dahia AlRasheed',
    deliveryPrice: 3
  },
  {
    arabicName: 'حي الصحابة',
    englishName: 'Hai AlSahabah',
    deliveryPrice: 3
  },
  {
    arabicName: 'الظهير',
    englishName: 'Al-Thheer',
    deliveryPrice: 3
  },
  {
    arabicName: 'وادي السير',
    englishName: 'Wadi AlSeer',
    deliveryPrice: 3
  },
  {
    arabicName: 'مرج الحمام',
    englishName: 'Marj Alhamam',
    deliveryPrice: 3.5
  },
  {
    arabicName: 'ضاحية النخيل',
    englishName: 'Arjan',
    deliveryPrice: 3.5
  },
  {
    arabicName: 'النزهه',
    englishName: 'AL-Nuzhah',
    deliveryPrice: 3.5
  },
  {
    arabicName: 'شارع الإستقلال',
    englishName: 'Al-Istiklal Street',
    deliveryPrice: 3.5
  },
  {
    arabicName: 'المقابلين',
    englishName: 'Al-Muqabelien',
    deliveryPrice: 4
  },
  {
    arabicName: 'أبو السوس',
    englishName: 'Abu AlSus',
    deliveryPrice: 3.5
  },
  {
    arabicName: 'الجبيهه',
    englishName: 'Al-Jbeehah',
    deliveryPrice: 4
  },
  {
    arabicName: 'الكماليه',
    englishName: 'AlKamaliah',
    deliveryPrice: 4
  },
  {
    arabicName: 'البنيات',
    englishName: 'AlBniat',
    deliveryPrice: 4
  },
  {
    arabicName: 'حي نزال',
    englishName: 'Hai Nazzal',
    deliveryPrice: 4
  },
  {
    arabicName: 'الزهور',
    englishName: 'AlZhour',
    deliveryPrice: 4
  },
  {
    arabicName: 'الخزنة',
    englishName: 'AlKhaznah',
    deliveryPrice: 4.5
  },
  {
    arabicName: 'دوار المشاغل',
    englishName: 'Al-Mashaghel Circle',
    deliveryPrice: 4
  },
  {
    arabicName: 'الهاشمي الشمالي',
    englishName: 'AlHashmi AlShamali',
    deliveryPrice: 4
  },
  {
    arabicName: 'جبل التاج',
    englishName: 'Jabal AlTaj',
    deliveryPrice: 5
  },
  {
    arabicName: 'الأشرفية',
    englishName: 'AlAshrafiah',
    deliveryPrice: 5
  },
  {
    arabicName: 'جبل النصر',
    englishName: 'Jabal AlNasr',
    deliveryPrice: 5
  },
  {
    arabicName: 'ابو نصير',
    englishName: 'Abu Nseer',
    deliveryPrice: 4.5
  },
  {
    arabicName: 'الملكة علياء',
    englishName: 'AlMalekah Alia',
    deliveryPrice: 4.5
  },
  {
    arabicName: 'طارق',
    englishName: 'Tareq',
    deliveryPrice: 4.5
  },
  {
    arabicName: 'شفا بدران',
    englishName: 'Shafa Badran',
    deliveryPrice: 5
  },
  {
    arabicName: 'اليادوده',
    englishName: 'Alyadodah',
    deliveryPrice: 5
  },
  {
    arabicName: 'خريبة السوق',
    englishName: 'Khrebet AlSouq',
    deliveryPrice: 5
  },
  {
    arabicName: 'جاوا',
    englishName: 'Jawah',
    deliveryPrice: 6
  },
  {
    arabicName: 'ايكيا',
    englishName: 'IKEA',
    deliveryPrice: 6
  },
  {
    arabicName: 'طبربور',
    englishName: 'Tabarbour',
    deliveryPrice: 4
  },
  {
    arabicName: 'ناعور',
    englishName: 'Naour',
    deliveryPrice: 4.5
  },
  {
    arabicName: 'القويسمة',
    englishName: 'AlQweesmeh',
    deliveryPrice: 4.5
  },
  {
    arabicName: 'وادي الرمم',
    englishName: 'Wadi AlRemam',
    deliveryPrice: 4.5
  },
  {
    arabicName: 'ام نواره',
    englishName: 'Um Nowarah',
    deliveryPrice: 4.5
  },
  {
    arabicName: 'ابو عليا',
    englishName: 'Abu Aliah',
    deliveryPrice: 4.5
  },
  {
    arabicName: 'دوار الدبابة',
    englishName: 'AlDababh Circle',
    deliveryPrice: 4.5
  },
  {
    arabicName: 'وسط البلد',
    englishName: 'Down Town',
    deliveryPrice: 5
  },
  {
    arabicName: 'الجمرك',
    englishName: 'AlJumruq',
    deliveryPrice: 5
  },
  {
    arabicName: 'ماركا',
    englishName: 'Marqa',
    deliveryPrice: 5
  },
  {
    arabicName: 'الفحيص',
    englishName: 'Fhees',
    deliveryPrice: 5
  },
  {
    arabicName: 'ماحص',
    englishName: 'Mahes',
    deliveryPrice: 5
  }
];

const CheckoutScreen = () => {
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [governorate, setGovernorate] = useState('');
  const [deliveryPrice, setDeliveryPrice] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadMoreItems = event => {
    if (event.target.scrollTop === event.target.scrollHeight) {
      //user is at the end of the list so load more items
    }
  };

  const { uploadOrder } = useContext(OrderContext);
  const {
    resetCart,
    state: { cart }
  } = useContext(CartContext);

  const {
    state: { language }
  } = useContext(LanguageContext);

  const props = {
    language
  };

  const classes = useStyles(props);
  const history = useHistory();

  useEffect(() => {
    if (!cart.length) history.push('/');

    new Promise((resolve, reject) => {
      console.log('called');
      let total = deliveryPrice;
      cart.forEach(({ price, amount }) => (total += price * amount));
      resolve(total);
    }).then(total => setTotalPrice(total));
  }, [deliveryPrice]);

  const handleChange = event => {
    setPaymentMethod(event.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const items = cart.map(({ itemId, amount }) => ({ itemId, amount }));
    const res = await uploadOrder({
      fullName,
      phoneNumber,
      address,
      governorate,
      deliveryPrice,
      totalPrice,
      paymentMethod,
      items
    });

    if (res.error) {
      setModalText('There is a problem in sending your order');
    } else {
      setModalText('Order sent successfully');
      setFullName('');
      setPhoneNumber('');
      setAddress('');
      setGovernorate(null);
      setDeliveryPrice(null);
      setTotalPrice(null);
      setPaymentMethod(null);
      resetCart();

      setTimeout(() => {
        history.push('/');
      }, 2000);
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  };

  return (
    <div>
      <NavBar />
      <div className={classes.toolbar}></div>
      <Container style={{ direction: language === 'en' ? 'ltr' : 'rtl' }}>
        <Typography className={classes.title}>
          {language === 'en' ? 'Information Details' : 'معلومات التواصل'}
        </Typography>
        <Divider style={{ marginBottom: '3%' }} />
        <Grid container>
          <Grid md={6} xs={12}>
            <Typography className={classes.label}>
              {language === 'en' ? 'Full Name' : 'الإسم الكامل'}
            </Typography>
            <TextField
              value={fullName}
              onChange={e => setFullName(e.target.value)}
              className={classes.textField}
              placeholder="ex: Ahmad Ali"
              variant="outlined"
            />
          </Grid>
          <Grid md={6} xs={12}>
            <Typography className={classes.label}>
              {language === 'en' ? 'Phone Number' : 'رقم الهاتف'}
            </Typography>
            <TextField
              value={phoneNumber}
              type="number"
              onChange={e => setPhoneNumber(e.target.value)}
              className={classes.textField}
              placeholder="07XXXXXXXX"
              variant="outlined"
            />
          </Grid>
          <Grid md={6} xs={12}>
            <Typography className={classes.label}>
              {language === 'en' ? 'Address' : 'العنوان'}
            </Typography>
            <TextField
              value={address}
              onChange={e => setAddress(e.target.value)}
              className={classes.textField}
              placeholder="ex: Amman-Abdoun"
              variant="outlined"
            />
          </Grid>
          <Grid md={6} xs={12}>
            <Typography className={classes.label}>
              {language === 'en' ? 'Select the governorate' : 'اختر المحافظة'}
            </Typography>
            <Select
              placeholder="Choose Governorate"
              variant="outlined"
              MenuProps={{
                PaperProps: {
                  onScroll: loadMoreItems,
                  style: { maxHeight: windowHeight / 4 }
                }
              }}
              className={classes.select}
              onChange={event => {
                setGovernorate(event.target.value.englishName);
                setDeliveryPrice(event.target.value.deliveryPrice);
              }}
            >
              {governorates.map(governorate => (
                <MenuItem value={governorate}>
                  {language === 'en'
                    ? governorate.englishName
                    : governorate.arabicName}
                </MenuItem>
              ))}
            </Select>
            {governorate && governorate.startsWith('Amman') && (
              <>
                <Typography className={classes.label}>
                  {language === 'en' ? 'Select the region' : 'اختر المنطقة'}
                </Typography>
                <Select
                  placeholder="Choose Address"
                  variant="outlined"
                  MenuProps={{
                    PaperProps: {
                      onScroll: loadMoreItems,
                      style: { maxHeight: windowHeight / 4 }
                    }
                  }}
                  className={classes.select}
                  onChange={event => {
                    setGovernorate('Amman - ' + event.target.value.englishName);
                    setDeliveryPrice(event.target.value.deliveryPrice);
                  }}
                >
                  {ammanRegions.map(governorate => (
                    <MenuItem value={governorate}>
                      {language === 'en'
                        ? governorate.englishName
                        : governorate.arabicName}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </Grid>
          <Grid className={classes.paymentContainer} xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {language === 'en' ? 'Payment method' : 'طريقة الدفع'}
              </FormLabel>
              <RadioGroup
                aria-label="payment"
                name="payment1"
                value={paymentMethod}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="visa on delivery"
                  control={<Radio color="primary" />}
                  label={
                    language === 'en' ? 'Visa on delivery' : 'فيزا عند التوصيل'
                  }
                />
                <FormControlLabel
                  value="cash on delivery"
                  control={<Radio color="primary" />}
                  label={
                    language === 'en' ? 'Cash on delivery' : 'كاش عند التوصيل'
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {deliveryPrice ? (
          <Typography className={classes.deliveryPrice}>
            {language === 'en' ? 'Delivery Price:' : 'سعر التوصيل: '} {deliveryPrice} {language === 'en' ? 'JD' : 'دينار'}
          </Typography>
        ) : null}
        <Typography className={classes.totalPrice}>
          {language === 'en' ? 'Total Price: ' : 'السعر الكلي: '} {totalPrice} {language === 'en' ? 'JD' : 'دينار'}
        </Typography>
        <Button
          color="secondary"
          className={classes.submitButton}
          variant="contained"
          onClick={() => {
            if (
              !fullName ||
              !phoneNumber ||
              !address ||
              !governorate ||
              !deliveryPrice ||
              !paymentMethod
            ) {
              return;
            }

            setModalText(
              language === 'en'
                ? 'Do you want to submit this?'
                : 'هل أنت متأكد من الإرسال؟'
            );
            setShowConfirmButton(true);
            setModalVisible(true);
          }}
        >
          {language === 'en' ? 'Submit' : 'أرسل'}
        </Button>
        <CustomModal
          title={language === 'en' ? 'Warning!' : '!تأكيد'}
          content={modalText}
          visible={modalVisible}
          onCancel={() => {
            setModalVisible(false);
          }}
          onConfirm={handleSubmit}
          showConfirmButton={showConfirmButton}
          loading={loading}
        />
      </Container>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  title: {
    color: '#92c83e',
    fontSize: '2rem',
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right')
  },
  textField: {
    width: isMobileOnly ? '100%' : windowWidth / 3.5,
    marginBottom: '3%'
  },
  select: {
    width: isMobileOnly ? '100%' : windowWidth / 3.5,
    marginBottom: '3%'
  },
  label: {
    marginBottom: isMobileOnly ? '3%' : '2%',
    fontWeight: 'bold',
    textAlign: isMobileOnly
      ? ({ language }) => (language === 'en' ? 'left' : 'right')
      : null
  },
  deliveryPrice: {
    color: '#92c83e',
    fontSize: '1.5rem',
    marginTop: '1%',
    textAlign: 'right'
  },
  totalPrice: {
    color: '#92c83e',
    fontSize: '1.5rem',
    marginTop: '1%',
    textAlign: 'right',
    marginBottom: '1%'
  },
  paper: {
    width: windowWidth / 4,
    height: windowHeight / 6
  },
  submitButton: {
    float: 'right',
    width: isMobileOnly ? windowWidth / 4 : windowWidth / 7,
    '&:hover': {
      color: 'white'
    },
    alignSelf: 'center',
    marginBottom: '1%'
  }
}));

export default CheckoutScreen;
