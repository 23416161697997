import React from "react";
import { Box } from "@material-ui/core";

const data = [
  require('../images/1.jpg'),
  require('../images/2.jpg'),
  require('../images/3.jpg'),
  require('../images/4.jpg'),
  require('../images/5.jpg'),
  require('../images/6.jpg')
]

const ArabicScreen = () => {
  return (
    <Box>
      {data.map((item) => (
        <img
          src={item.default}
          alt="menu"
          style={{ width: '100%', height: '100%' }}
        />
      ))}

    </Box>
  );
};

export default ArabicScreen;