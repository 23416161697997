import React from "react";
import { Box } from "@material-ui/core";

const data = [
  require('../images/1_english.jpg'),
  require('../images/2_english.jpg'),
  require('../images/3_english.jpg'),
  require('../images/4_english.jpg'),
  require('../images/5_english.jpg'),
  require('../images/6_english.jpg')
]

const EnglishScreen = () => {
  return (
    <Box>
      {data.map((item) => (
        <img
          src={item.default}
          alt="menu"
          style={{ width: '100%', height: '100%' }}
        />
      ))}

    </Box>
  );
};

export default EnglishScreen;